import { useLocation } from 'react-router-dom';
import './ForgotPassword.css';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { validateEmailFormat } from '../../infrastructure/helpers/validation_helper';
import { FormHelperText } from '@mui/material';

const ForgotPassword = () => {
    const location = useLocation();
    const [email, setEmail] = useState(location.state?.email || '');
    const [emailValidationMessage, setEmailValidationMessage] = useState('');

    const validateForm = (email) => {
        let isValid = true;

        if(!email || email.trim() === '') {
            isValid = false;
            setEmailValidationMessage('Email is required');
        } else {
            if(!validateEmailFormat(email)) {
                isValid = false;
                setEmailValidationMessage('Invalid email format');
            }
        }

        return isValid;
    }

    const submitForm = (e) => {
        e.preventDefault();      
        if(!validateForm(email)) return;

        // TODO: Implement forgot password logic
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailValidationMessage('');
    }

    return (
        <div>
            <div>
                <Logo />
                <div className='auth-container'>
                    <h2 className='title'>Forgot password</h2>
                    <form onSubmit={submitForm}>
                        <TextField
                            id="email"
                            label="Username or email"
                            variant="outlined"
                            fullWidth
                            defaultValue={email}
                            className='text-input'
                            onChange={onEmailChange}
                        />
                        <FormHelperText error={true} style={{textAlign: 'right', marginBottom: '12px'}}>
                            <span dangerouslySetInnerHTML={{__html: emailValidationMessage}}></span>
                        </FormHelperText>
                        <button className='btn-blue btn-auth' type='submit'>Send reset code</button>
                    </form>
                </div>
            </div>
            <div>
                <p className='login-subtitle'>Already have your password? Return to <a className='login-links' href="/">Sign in</a></p>                
            </div>    
        </div>
    );
}

export default ForgotPassword;