import { chartWaterQualityValueTypeEnum } from "../api/server_calls";
import { lightsOperations } from "./mqtt_protocol";

const deviceTypeEnum = {
  TEMPERATURE: 0,
  FEEDER: 1,
  LIGHTS_RGB: 2,
  LIGHTS: 3,
  POWER_STRIP_3_SOCKET: 4,
  POWER_STRIP_4_SOCKET: 5,
  DOSING_PUMP: 6,
  WATER_FLOW: 7,
  LED_CONTROLLER_1_CHANNEL: 8,
  POWER_STRIP_6_SOCKET: 9,
  UNUSED_1: 10,
  COOLER_CONTROLLER: 11,
  UNUSED_2: 12,
  RTC: 13,
  WATER_QUALITY_5IN1: 14,
  WATER_QUALITY_8IN1: 15,
  WATER_QUALITY_PANEL_ITEM: 16,
};

const devicePanelScreenTypesEnum = {
  none: 0,
  ec: 1,
  ph: 2,
  sg: 3,
  tds: 4,
  temperature: 5,
  orp: 6,
  waterflow: 7,
  dissolvedoxygen: 8,
  turbidity: 9,
  salt: 10,
  cf: 11,
};

const allDeviceTypes = [
  deviceTypeEnum.WATER_QUALITY_5IN1,
  deviceTypeEnum.FEEDER,
  deviceTypeEnum.LIGHTS,
  deviceTypeEnum.POWER_STRIP_3_SOCKET,
  deviceTypeEnum.DOSING_PUMP,
  deviceTypeEnum.WATER_FLOW,
  deviceTypeEnum.COOLER_CONTROLLER,
  deviceTypeEnum.LED_CONTROLLER_1_CHANNEL,
  deviceTypeEnum.RTC

];

const celsiusToFahrenheit = (celsius)  => {
  const fahrenheit = celsius * 9.0/5.0 + 32.0;
  return fahrenheit;
}

const getTemperatureByUnit = (temperature, temperatureUnit) => {
  let celsiusVal = temperature / 10.0;
  if (temperatureUnit === 1) { 
    //fahrenheit
    let fahrenheitVal = celsiusToFahrenheit(celsiusVal);
    return fahrenheitVal.toFixed(1);
  } else {
    //celsius
    return celsiusVal.toFixed(1);
  }
}

const getTemperatureUnitText = (temperatureUnit) => {
    if(temperatureUnit === 1){
        return " °F";
    } else {
        return " °C";
    }
}

const getGlobalTemperature = (temperature, temperatureUnit) => {
  if(temperatureUnit === 1){
    return (temperature - 32) * 5 / 9 * 10;      
  } else {
    return temperature * 10;
  }
}

const displayNumber = (value) => {
  const number = parseFloat(value);

    if (isNaN(number)) {
        return number.toString();
    }

    return number.toFixed(1);
}

const getValueDividerForWaterQualityType = (type) => {
  switch (type) {
    case chartWaterQualityValueTypeEnum.none:
      return 1.0;
    case chartWaterQualityValueTypeEnum.temperature:
      return 100.0;
    case chartWaterQualityValueTypeEnum.orp:
      return 1.0;
    case chartWaterQualityValueTypeEnum.ph:
      return 100.0;
    case chartWaterQualityValueTypeEnum.cf:
      return 100.0;
    case chartWaterQualityValueTypeEnum.sg:
      return 1000.0;
    case chartWaterQualityValueTypeEnum.salt:
      return 10000.0;
    case chartWaterQualityValueTypeEnum.ec:
      return 1.0;
    case chartWaterQualityValueTypeEnum.tds:
      return 1.0;
    default:
      return 1.0;
  }
}

const getDeviceValueStrings = (deviceType, panelScreenType, isOnline, values, temperatureUnit) => {
  let valueString = "";
  let unitString = "";
  let operationString = "";

  switch (deviceType) {
    case deviceTypeEnum.COOLER_CONTROLLER:
      if (isOnline) {
        if (values?.on) {
          valueString = "cooler_on";
        }
        else {
          valueString = "cooler_idle";
        }
      }
      break;
    case deviceTypeEnum.DOSING_PUMP:

      break;
    case deviceTypeEnum.FEEDER:

      break;
    case deviceTypeEnum.LED_CONTROLLER_1_CHANNEL:
    case deviceTypeEnum.LIGHTS:
    case deviceTypeEnum.LIGHTS_RGB:
      valueString = values?.currentLightsPercentage?.toFixed();
      if (values?.currentLightsOperation === lightsOperations.AQUAELF_LIGHTS_OPERATION_SUNRISE) {
        operationString = "lbl_sunrise";
      }
      else if (values?.currentLightsOperation === lightsOperations.AQUAELF_LIGHTS_OPERATION_SUNSET) {
        operationString = "lbl_sunset";
      }
      unitString = "%";
      break;
    case deviceTypeEnum.POWER_STRIP_3_SOCKET:
    case deviceTypeEnum.POWER_STRIP_4_SOCKET:
    case deviceTypeEnum.POWER_STRIP_6_SOCKET:

      break;
    case deviceTypeEnum.RTC:
      valueString = (isOnline ? "Online" : "");
      break;
    case deviceTypeEnum.WATER_QUALITY_5IN1:
    case deviceTypeEnum.WATER_QUALITY_8IN1:
    case deviceTypeEnum.WATER_QUALITY_PANEL_ITEM:
      switch (panelScreenType) {
        case devicePanelScreenTypesEnum.ec:
          valueString = values?.ec?.toFixed();
          unitString = "us/cm";
          break;
        case devicePanelScreenTypesEnum.orp:
          valueString = values?.orp?.toFixed();
          unitString = "mV";

          break;
        case devicePanelScreenTypesEnum.cf:
          valueString = ((values?.cf?? 0) / getValueDividerForWaterQualityType(devicePanelScreenTypesEnum.cf)).toFixed(2);
          unitString = "CF";

          break;
        case devicePanelScreenTypesEnum.salt:
          valueString = ((values?.salt?? 0) / getValueDividerForWaterQualityType(devicePanelScreenTypesEnum.salt)).toFixed(3);
          unitString = "";

          break;
        case devicePanelScreenTypesEnum.ph:
          valueString = ((values?.ph ?? 0) / getValueDividerForWaterQualityType(devicePanelScreenTypesEnum.ph)).toFixed(1);
          unitString = "";

          break;
        case devicePanelScreenTypesEnum.sg:

          let val = ((values?.sg ?? 0) / getValueDividerForWaterQualityType(devicePanelScreenTypesEnum.sg)).toFixed(3);
          if (val.startsWith("0")) {
            val = val.substring(1, val.length);
          }
          valueString = val;
          unitString = "";

          break;
        case devicePanelScreenTypesEnum.tds:
          valueString = values?.tds?.toFixed();
          unitString = "ppm";

          break;
        case devicePanelScreenTypesEnum.temperature:
          if (temperatureUnit === 1) { //fahrenheit
            let celsiusVal = (values?.temperature ?? 0) / 10.0;
            let fahrenheitVal = celsiusToFahrenheit(celsiusVal);
            valueString = fahrenheitVal.toFixed(1);
            unitString = "°F";
          }
          else {
            valueString = ((values?.temperature ?? 0) / 10).toFixed(1);
            unitString = "°C";
          }
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return { valueString, operationString, unitString };
}

const getDeviceTypeNameTranslationKey = deviceType => {
  switch (deviceType) {
    case deviceTypeEnum.TEMPERATURE:
      return 'module_name_temperature';
    case deviceTypeEnum.FEEDER:
      return 'module_name_feeder';
    case deviceTypeEnum.LIGHTS:
      return 'module_name_lights';
    case deviceTypeEnum.LIGHTS_RGB:
      return 'module_name_lights_rgb';
    case deviceTypeEnum.LED_CONTROLLER_1_CHANNEL:
      return 'module_name_lights_controller';
    case deviceTypeEnum.WATER_QUALITY_5IN1:
    case deviceTypeEnum.WATER_QUALITY_8IN1:
    case deviceTypeEnum.WATER_QUALITY_PANEL_ITEM:
      return 'module_name_water_quality';
    case deviceTypeEnum.POWER_STRIP_6_SOCKET:
      return 'module_name_power';
    case deviceTypeEnum.POWER_STRIP_4_SOCKET:
      return 'module_name_power';
    case deviceTypeEnum.POWER_STRIP_3_SOCKET:
      return 'module_name_power';
    case deviceTypeEnum.DOSING_PUMP:
      return 'module_name_dosing_pump';
    case deviceTypeEnum.WATER_FLOW:
      return 'module_name_water_flow';
    case deviceTypeEnum.COOLER_CONTROLLER:
      return 'module_name_cooler';
    case deviceTypeEnum.RTC:
      return 'module_name_rtc';
    default:
      return 'module_name_unknown';
  }
};

const getDeviceTypeWifiPrefix = deviceType => {
  switch (deviceType) {
    case deviceTypeEnum.TEMPERATURE:
      return 'AquaPulse_Temperature_';
    case deviceTypeEnum.WATER_QUALITY_5IN1:
      return 'AquaPulse_Water_Quality_';
    case deviceTypeEnum.WATER_QUALITY_8IN1:
      return 'AquaPulse_Water_Quality_';
    case deviceTypeEnum.FEEDER:
      return 'AquaPulse_Feeder_';
    case deviceTypeEnum.LIGHTS:
    case deviceTypeEnum.LED_CONTROLLER_1_CHANNEL:
      return 'AquaPulse_Lights_';
    case deviceTypeEnum.LIGHTS_RGB:
      return 'AquaPulse_Lights_';
    case deviceTypeEnum.POWER_STRIP_3_SOCKET:
      return 'AquaPulse_Power_Box_';
    case deviceTypeEnum.POWER_STRIP_4_SOCKET:
      return 'AquaPulse_Power_Box_';
    case deviceTypeEnum.POWER_STRIP_6_SOCKET:
      return 'AquaPulse_Power_Box_';

    case deviceTypeEnum.DOSING_PUMP:
      return 'AquaPulse_Dosing_';
    case deviceTypeEnum.WATER_FLOW:
      return 'AquaPulse_Water_Flow_';

    case deviceTypeEnum.COOLER_CONTROLLER:
      return 'AquaPulse_Cooler_';

    case deviceTypeEnum.RTC:
      return 'AquaPulse_RTC_';
    default:
      return 'Unknown';
  }
};

const getDeviceTypeIconName = deviceType => {
  switch (deviceType) {
    case deviceTypeEnum.TEMPERATURE:
      return 'coolant-temperature';
    case deviceTypeEnum.FEEDER:
      return 'food';
    case deviceTypeEnum.LIGHTS:
      return 'led-strip';
    case deviceTypeEnum.LIGHTS_RGB:
      return 'led-strip';
    case deviceTypeEnum.LED_CONTROLLER_1_CHANNEL:
      return 'led-strip';
    case deviceTypeEnum.POWER_STRIP_3_SOCKET:
      return 'power-socket-eu';
    case deviceTypeEnum.POWER_STRIP_4_SOCKET:
      return 'power-socket-eu';
    case deviceTypeEnum.POWER_STRIP_5_SOCKET:
      return 'power-socket-eu';
    case deviceTypeEnum.DOSING_PUMP:
      return 'water-pump';
    case deviceTypeEnum.WATER_FLOW:
      return 'water-pump';
    case deviceTypeEnum.COOLER_CONTROLLER:
      return 'fan';
    case deviceTypeEnum.WATER_QUALITY_5IN1:
      return 'coolant-temperature';
    case deviceTypeEnum.WATER_QUALITY_8IN1:
      return 'coolant-temperature';
    case deviceTypeEnum.RTC:
      return 'clock';
    default:
      return 'fan';
  }
};

const getDeviceRoute = deviceType => {
  switch (deviceType) {
    case deviceTypeEnum.TEMPERATURE:
      return 'TemperatureScreen';
    case deviceTypeEnum.FEEDER:
    case deviceTypeEnum.COOLER_CONTROLLER:
      return 'FeederScreen';
    case deviceTypeEnum.LIGHTS:
    case deviceTypeEnum.LIGHTS_RGB:
    case deviceTypeEnum.LED_CONTROLLER_1_CHANNEL:
      return 'LightsScreen';
    case deviceTypeEnum.POWER_STRIP_3_SOCKET:
    case deviceTypeEnum.POWER_STRIP_4_SOCKET:
    case deviceTypeEnum.POWER_STRIP_6_SOCKET:
      return 'PowerStripScreen';
    case deviceTypeEnum.DOSING_PUMP:
      return 'DoserPumpScreen';
    default:
      return '';
  }
};

export {
  allDeviceTypes,
  celsiusToFahrenheit, 
  devicePanelScreenTypesEnum, 
  deviceTypeEnum, 
  getDeviceRoute, 
  getDeviceTypeIconName, 
  getDeviceTypeNameTranslationKey, 
  getDeviceTypeWifiPrefix, 
  getDeviceValueStrings,
  getTemperatureByUnit,
  getTemperatureUnitText,
  getGlobalTemperature,
  displayNumber
};

