import { createContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import MqttManager from '../infrastructure/helpers/mqtt_helper';
import { useAtom } from "jotai";
import { atom_devices, atom_mqttConnectionStatus, atom_feederPumpLastActions, atom_userData, atom_tanks, atom_notificationSettings, atom_deviceValues, atom_deviceLastUpdates, atom_currentLanguage, atom_timeZonesList, atom_currentTankId } from '../infrastructure/jotai/store';
import { sendMqttPingToOrganizationId, subscribeToMqttTopic } from '../infrastructure/helpers/mqtt_sender';
import { processDevicesFullData } from '../infrastructure/helpers/devicesHelper';
import { api_get_all_user_data, api_get_timezones } from '../infrastructure/api/server_calls';
import moment from "moment";
import _ from 'lodash';
import { mqttMessageTypes } from "../infrastructure/helpers/mqtt_protocol";

export const Context = createContext();

const _mqttClient = MqttManager.getInstance().mqttClient;
const MQTT_PING_INTERVAL_SECONDS = 45;
const MQTT_RECONNECT_INTERVAL_SECONDS = 5;



const AuthContext = ({ children }) => {
    const auth = getAuth();

    const [, setDevices] = useAtom(atom_devices);
    const [feederPumpLastActions, setFeederPumpLastActions] = useAtom(atom_feederPumpLastActions);
    const [userData, setUserData] = useAtom(atom_userData);
    const [, setTanks] = useAtom(atom_tanks);
    const [, setNotificationSettings] = useAtom(atom_notificationSettings);
    const [mqttConnectionStatus, setMqttConnectionStatus] = useAtom(atom_mqttConnectionStatus);
    const [deviceValues, setDeviceValues] = useAtom(atom_deviceValues);
    const [deviceLastUpdates, setDeviceLastUpdates] = useAtom(atom_deviceLastUpdates);
    const [currentLanguage, ] = useAtom(atom_currentLanguage);
    const [, setTimeZonesList] = useAtom(atom_timeZonesList);
    const [currentTankId, setCurrentTankId] = useAtom(atom_currentTankId);

    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    const [canDoMqtt, setCanDoMqtt] = useState(false);

    const updateFeederPumpLastAction = (deviceId, pl) => {
        let mmt = moment();
        let seconds = mmt.seconds();
        let minutes = mmt.minutes();
        let hour = mmt.hours();
        seconds = hour * 60 * 60 + minutes * 60 + seconds;

        let item = { id: deviceId, timeStamp: mmt.toJSON(), seconds: seconds, duration: pl.duration ?? 0 };
        let foundItem = feederPumpLastActions.find(x => x.id === item.id);

        if (!foundItem || (foundItem.seconds !== item.seconds)) {

            setFeederPumpLastActions(state => {
                let arr = state.filter(x => x.id !== deviceId);
                return [...arr, item];
            });
        }
    };

    const updateDeviceLastUpdate = (deviceId) => {
        let mmt = moment();
        let seconds = mmt.seconds();
        let minutes = mmt.minutes();
        let hour = mmt.hours();
        seconds = hour * 60 * 60 + minutes * 60 + seconds;

        let item = { id: deviceId, lastDataReceived: mmt.toJSON(), seconds: seconds };
        let foundItem = deviceLastUpdates.find(x => x.id === item.id);
        let foundSeconds = foundItem?.seconds ?? 0;

        if (!foundItem || (item.seconds - foundSeconds > 30)) {
            setDeviceLastUpdates(state => {
                let arr = state.filter(x => x.id !== deviceId);
                return [...arr, item];
            });
        }

    };

    const updateDeviceValue = (deviceId, pl) => {

        let item = { ...pl, id: deviceId };
        let foundItem = deviceValues.find(x => x.id === item.id);
        if (!foundItem || !(_.isEqual(item, foundItem))) {
            setDeviceValues(state => {

                let arr = state.filter(x => x.id !== deviceId);
                return [...arr, item];
            });
        }
    };

    const onMqttConnect = () => {
        setMqttConnectionStatus(true);
        if (userData) {
            if (userData.organizationIds && userData.organizationIds.length > 0) {
                userData.organizationIds.forEach(id => {
                    sendMqttPingToOrganizationId(id, true);
                })
            }
        }
    };

    const processMqttMessage = (msg) => {
        let obj = JSON.parse(msg.payloadString);

        if (obj) {
            const deviceId = obj.deviceId;
            const msgType = obj.type;
            const payload = obj.payload;

            if (deviceId) {
                updateDeviceLastUpdate(deviceId);

                let pl = {};


                switch (msgType) {
                    case mqttMessageTypes.MQTT_MSG_TYPE_RELAY_READING:

                        pl.socket1On = payload?.c1;
                        pl.socket2On = payload?.c2;
                        pl.socket3On = payload?.c3;
                        pl.socket4On = payload?.c4;

                        pl.socket1InitOn = payload?.ci1;
                        pl.socket2InitOn = payload?.ci2;
                        pl.socket3InitOn = payload?.ci3;
                        pl.socket4InitOn = payload?.ci4;

                        pl.schedule_on_1_1 = payload?.s1on1;
                        pl.schedule_off_1_1 = payload?.s1off1;
                        pl.schedule_on_1_2 = payload?.s1on2;
                        pl.schedule_off_1_2 = payload?.s1off2;

                        pl.schedule_on_2_1 = payload?.s2on1;
                        pl.schedule_off_2_1 = payload?.s2off1;
                        pl.schedule_on_2_2 = payload?.s2on2;
                        pl.schedule_off_2_2 = payload?.s2off2;

                        pl.schedule_on_3_1 = payload?.s3on1;
                        pl.schedule_off_3_1 = payload?.s3off1;
                        pl.schedule_on_3_2 = payload?.s3on2;
                        pl.schedule_off_3_2 = payload?.s3off2;

                        pl.schedule_on_4_1 = payload?.s4on1;
                        pl.schedule_off_4_1 = payload?.s4off1;
                        pl.schedule_on_4_2 = payload?.s4on2;
                        pl.schedule_off_4_2 = payload?.s4off2;

                        pl.localIpAddress = obj.ip;
                        pl.firmwareVersion = obj.version;

                        updateDeviceValue(deviceId, pl);

                        break;
                    case mqttMessageTypes.MQTT_MSG_TYPE_LIGHTS_READING:

                        pl.currentLightsOperation = payload?.op;
                        pl.currentLightsPercentage = payload?.per;

                        pl.schedule1Sunrise = payload?.s1sr;
                        pl.schedule1Sunset = payload?.s1ss;
                        pl.schedule2Sunrise = payload?.s2sr;
                        pl.schedule2Sunset = payload?.s2ss;

                        pl.schedule1SunriseDuration = payload?.sr1dr;
                        pl.schedule2SunriseDuration = payload?.sr2dr;

                        pl.schedule1SunsetDuration = payload?.ss1dr;
                        pl.schedule2SunsetDuration = payload?.ss2dr;

                        pl.schedule1SunriseMax = payload?.sr1mx;
                        pl.schedule2SunriseMax = payload?.sr2mx;

                        pl.schedule1SunsetMin = payload?.ss1mi;
                        pl.schedule2SunsetMin = payload?.ss2mi;

                        pl.allDay = (payload?.schd === 1);
                        pl.allDayData = payload?.arrd;

                        pl.localIpAddress = obj.ip;
                        pl.firmwareVersion = obj.version;

                        updateDeviceValue(deviceId, pl);

                        break;
                    case mqttMessageTypes.MQTT_MSG_TYPE_DOSER_READING:

                        pl.s1 = payload?.s1;
                        pl.s2 = payload?.s2;

                        pl.s1_dur = payload?.s1_dur;
                        pl.s2_dur = payload?.s2_dur;

                        pl.localIpAddress = obj.ip;
                        pl.firmwareVersion = obj.version;

                        updateDeviceValue(deviceId, pl);

                        break;
                    case mqttMessageTypes.MQTT_MSG_TYPE_RTC_READING:

                        pl.localIpAddress = obj.ip;
                        pl.firmwareVersion = obj.version;

                        updateDeviceValue(deviceId, pl);
                        break;
                    case mqttMessageTypes.MQTT_MSG_TYPE_MAIN_5IN1_READING:

                        pl.tds = payload?.tds;
                        pl.temperature = payload?.tmp;
                        pl.ph = payload?.ph;
                        pl.sg = payload?.sg;
                        pl.ec = payload?.ec;

                        pl.coolerTarget = payload?.ct;

                        pl.localIpAddress = obj.ip;
                        pl.firmwareVersion = obj.version;

                        updateDeviceValue(deviceId, pl);

                        break;
                    case mqttMessageTypes.MQTT_MSG_TYPE_MAIN_8IN1_READING:

                        pl.tds = payload?.tds;
                        pl.temperature = payload?.tmp;
                        pl.ph = payload?.ph;
                        pl.sg = payload?.sg;
                        pl.ec = payload?.ec;
                        pl.cf = payload?.cf;
                        pl.orp = payload?.orp;
                        pl.salt = payload?.salt;

                        pl.coolerTarget = payload?.ct ?? 0;

                        pl.localIpAddress = obj.ip;
                        pl.firmwareVersion = obj.version;

                        updateDeviceValue(deviceId, pl);
                        break;
                    case mqttMessageTypes.MQTT_MSG_TYPE_FEEDER_READING:

                        pl.s1 = payload?.s1;
                        pl.s2 = payload?.s2;

                        pl.localIpAddress = obj.ip;
                        pl.firmwareVersion = obj.version;

                        updateDeviceValue(deviceId, pl);

                        break;
                    case mqttMessageTypes.MQTT_MSG_TYPE_COOLER_READING:

                        pl.on = payload?.on === 1 ? true : false;

                        pl.localIpAddress = obj.ip;
                        pl.firmwareVersion = obj.version;

                        updateDeviceValue(deviceId, pl);

                        break;
                    case mqttMessageTypes.MQTT_MSG_TYPE_FEEDER_FEED_EVENT:
                    case mqttMessageTypes.MQTT_MSG_TYPE_DOSER_RUN_EVENT:

                        pl.duration = payload.dur;

                        updateFeederPumpLastAction(deviceId, pl);

                        break;
                    default:
                        break;
                }
            }

        }
    };

    const onMqttConnectionLost = () => {
        setMqttConnectionStatus(false);
    };

    const onMqttMessageArrived = (msg) => {
        setMqttConnectionStatus(true);
        try {
            processMqttMessage(msg);
        }
        catch (err) {
        }
    };

    const disconnectFromMqtt = () => {
        if (_mqttClient.isConnected()) {
            _mqttClient.disconnect();
        }
    }

    const connectToMqtt = () => {
        if (auth.currentUser) {
            auth.currentUser.getIdToken().then(token => {
                if (!_mqttClient.isConnected()) {
                    _mqttClient.connect({
                        onSuccess: onMqttConnect,
                        useSSL: true,
                        reconnect: false,
                        userName: auth.currentUser.uid,
                        password: token,
                        mqttVersion: 3,
                    });
                }
            });
        }
    }

    const setMqttEventMethods = () => {
        _mqttClient.onMqttConnect = onMqttConnect;
        _mqttClient.onConnectionLost = onMqttConnectionLost;
        _mqttClient.onMessageArrived = onMqttMessageArrived;
    };

    useEffect(() => {
        setMqttEventMethods();
        setMqttConnectionStatus(false);
    }, []);

    useEffect(() => {
        let tm;

        if (canDoMqtt) {
            if (mqttConnectionStatus) {
                if (userData) {
                    if (userData.organizationIds) {
                        let orgIds = userData.organizationIds;
                        orgIds.forEach(orgId => {
                            subscribeToMqttTopic(orgId);
                            sendMqttPingToOrganizationId(orgId, true);
                        });

                        tm = setInterval(() => {
                            orgIds.forEach(orgId => {
                                sendMqttPingToOrganizationId(orgId);
                            });
                        }, MQTT_PING_INTERVAL_SECONDS * 1000);
                    }
                }
            }
            else {
                connectToMqtt();
                tm = setInterval(() => {
                    connectToMqtt();
                }, MQTT_RECONNECT_INTERVAL_SECONDS * 1000);
            }
        }
        else {
            disconnectFromMqtt();
        }

        return () => {
            if (tm) {
                clearInterval(tm);
            }
        }
    }, [canDoMqtt, mqttConnectionStatus]);

    useEffect(() => {
        let unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setLoading(false);

            if (currentUser) {
                setUser(currentUser);
                setCanDoMqtt(true);
                const token = currentUser.accessToken;

                api_get_all_user_data(token, "", "en")
                    .then(res => {
                        if (res.success) {
                            const data = res.data;

                            let _devices = data.devices;
                            let _feederDoserLastActions = data.feederDoserLastActions;
                            let _notifications = data.notifications;
                            let _orders = data.orders;
                            let _sockets = data.sockets;
                            let _tanks = data.tanks;
                            let _userData = { ...data.userData, fbToken: token };
                            let _names = data.names;

                            setUserData(_userData);

                            setTanks(_tanks);

                            if(!currentTankId){
                                let tankId = _tanks[0].id;
                                setCurrentTankId(tankId);
                            }                            
                            
                            setNotificationSettings(_notifications);
                            setFeederPumpLastActions(_feederDoserLastActions);

                            let processedDevicesData = processDevicesFullData(_devices, _names, _orders, _sockets);

                            setDevices(processedDevicesData);

                        }
                    })
                    .catch(err => {
                        
                    })

                api_get_timezones(currentLanguage).then(result => {
                    if (result.success) {
                        // dl(result);
                        setTimeZonesList(state => {
                        return result.data;
                        })
                    }
            
                    });
            }
            else {
                setUser(null);
                setCanDoMqtt(false);
            }
        });

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, [auth, currentLanguage, currentTankId, setCurrentTankId, setDevices, setFeederPumpLastActions, setNotificationSettings, setTanks, setUserData, setTimeZonesList]);

    const values = {
        user: user,
        setUser: setUser,
    };

    return <Context.Provider value={values}>
        {!loading && children}
    </Context.Provider>
}

export default AuthContext;