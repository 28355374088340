import './RtcModule.css';
import { useEffect, useState } from 'react';
import { ModuleTitleComponent } from '../components/Common/ModuleTitleComponent';
import { useAtom } from 'jotai';
import { atom_deviceValues, atom_devices, atom_deviceLastUpdates, atom_tanks } from '../infrastructure/jotai/store';
import { GetOnlineStatus, GetDevice, GetDeviceValue } from '../infrastructure/helpers/page_effect_helper';
import { ModuleSummary } from '../components/Common/ModuleSummary';
import { DownloadsComponent } from '../components/Common/DownloadsComponent';
import { RtcModuleSummaryTitleComponent } from '../components/Rtc/RtcModuleSummaryTitleComponent';

export const RtcModule = (data) => {
    const [deviceId, ] = useState(data?.currentDeviceId);
    const [deviceType, ] = useState(data?.deviceType);
    const [lastDataReceived, ] = useState(0);
    const [tank, setTank] = useState();

    const [valuesList, ] = useAtom(atom_deviceValues);
    const [devices,] = useAtom(atom_devices);
    const [deviceLastUpdates, ] = useAtom(atom_deviceLastUpdates);
    const [tanks, ] = useAtom(atom_tanks);

    const isOnline = GetOnlineStatus(deviceId, deviceLastUpdates);
    const device = GetDevice(deviceId, devices);
    const deviceValue = GetDeviceValue({device: device, valuesList: valuesList, deviceType: deviceType}).value;

    useEffect(() => {
        if(!device || !tanks || tanks.length === 0) return;
        setTank(tanks.find(t => t.id === device.tankId));
    }, [device, tanks]);

    const summaryTitle = <RtcModuleSummaryTitleComponent tank={tank} localIpAddress={deviceValue.localIpAddress}/>;

    return (
        <div>
            <ModuleTitleComponent moduleName={device?.name} id={device?.id}/>
            <ModuleSummary summaryTitle={summaryTitle} isOnline={isOnline} lastUpdated={lastDataReceived} moduleType="RTC Module" operation="None" version="1.0.31" />
            <DownloadsComponent link={"/"} title="RTC User Manual" size="3.2 MB"/>
        </div>
    );
}