import "./ModuleTitleComponent.css";

import { ReactComponent as ThreeDotsIcon } from "../../assets/icons/three-dots.svg";
import { ReactComponent as PenIcon } from "../../assets/icons/pen.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";

import { RenameModuleModalComponent } from "../Common/RenameModuleModalComponent";
import { RemoveModuleModalComponent } from "../Common/RemoveModuleModalComponent";

export const ModuleTitleComponent = (props) => {
    const { moduleName, id } = props;

    return (
        <div>
            <h5 className='module-title'>
                {moduleName}
                <div className="dropdown dropdown-menu-end">
                    <button className="title-icon-container" type="button" data-bs-toggle="dropdown" data-bs-target="#module-title-dropdown" aria-expanded="false">
                        <ThreeDotsIcon />
                    </button>
                    <ul className="dropdown-menu" id="module-title-dropdown">
                        <li><button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#rename-module-modal"><PenIcon /> Rename module</button></li>
                        <li><button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#remove-module-modal"><TrashIcon /> Remove module</button></li>
                    </ul>
                </div>
                
            </h5>
            <RenameModuleModalComponent moduleName={moduleName} id={id}/>
            <RemoveModuleModalComponent id={id}/>
        </div>
    )
}