import './Register.css';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google-logo.svg';
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, getAuth  } from 'firebase/auth';
import { auth } from "../../infrastructure/helpers/firebase.utils";
import { validateEmailFormat } from '../../infrastructure/helpers/validation_helper';
import { FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailValidationMessage, setEmailValidationMessage] = useState('');
    const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
    const [confirmPasswordValidationMessage, setConfirmPasswordValidationMessage] = useState('');
    const navigate = useNavigate();

    const validateForm = (email, password, confirmPassword) => {
        let isValid = true;

        if(!email || email.trim() === '') {
            setEmailValidationMessage('Email is required');
            isValid = false;
        } else {
            if(!validateEmailFormat(email)) {
                setEmailValidationMessage('Invalid email format');
                isValid = false;
            }
        }

        if(!password || password.trim() === '') {
            setPasswordValidationMessage('Password is required');
            isValid = false;
        }

        if(!confirmPassword || confirmPassword.trim() === '') {
            setConfirmPasswordValidationMessage('Confirm password is required');
            isValid = false;
        } else {
            if(password !== confirmPassword) {
                setConfirmPasswordValidationMessage('Passwords do not match');
                isValid = false;
            }
        }       

        return isValid;
    }

    const submitForm = (e) => {
        console.log('submit');
        e.preventDefault();
        if(!validateForm(email, password, confirmPassword)) return;

        const _auth = getAuth();
        console.log(email);
        console.log(password);
        createUserWithEmailAndPassword(_auth, email, password).then((userCredential) => {
            navigate("/");
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log('Error registering user: ', errorCode, errorMessage);
        });
    }

    const onGoogleRegister = async () => {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // const user = result.user;
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailValidationMessage('');
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordValidationMessage('');
    }

    const onConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordValidationMessage('');
    }

    return (
        <div>
            <div>
            <Logo />
                <div className='auth-container register-container'>
                    <form onSubmit={submitForm}>
                        <h2 className='title'>Register</h2>
                        <TextField
                            id="email"
                            name="disable-autofill-email"
                            label="Username or email"
                            variant="outlined"
                            fullWidth
                            defaultValue={email}
                            className='text-input'
                            onChange={onEmailChange}
                            autoComplete='new-email'
                        />
                        <FormHelperText error={true} style={{textAlign: 'right', marginBottom: '12px'}}>
                            <span dangerouslySetInnerHTML={{__html: emailValidationMessage}}></span>
                        </FormHelperText>
                        <TextField
                            id="password"
                            name="disable-autofill-password"
                            label="Password"
                            variant="outlined"
                            type="password"
                            fullWidth
                            defaultValue={password}
                            className='text-input'
                            onChange={onPasswordChange}
                            autoComplete='new-password'
                        />
                        <FormHelperText error={true} style={{textAlign: 'right', marginBottom: '12px'}}>
                            <span dangerouslySetInnerHTML={{__html: passwordValidationMessage}}></span>
                        </FormHelperText>
                        <TextField
                            id="confirm-password"
                            name="disable-autofill-confirm-password"
                            label="Confirm password"
                            variant="outlined"
                            type="password"
                            fullWidth
                            defaultValue={confirmPassword}
                            className='text-input'
                            onChange={onConfirmPasswordChange}
                            autoComplete='new-confirm-password'
                        />
                        <FormHelperText error={true} style={{textAlign: 'right', marginBottom: '12px'}}>
                            <span dangerouslySetInnerHTML={{__html: confirmPasswordValidationMessage}}></span>
                        </FormHelperText>
                        <button className='btn-blue btn-auth' type='submit'>Register</button>
                        <div className='separator'><span>or</span></div>
                        <button className='btn-white btn-social' onClick={onGoogleRegister}>
                            <GoogleIcon />
                            <span>Sign up with Google</span>
                        </button>
                    </form>
                </div>
            </div>
            <div>
                <p className='login-subtitle'>Already have an account? <a className='login-links' href="/auth">Sign in</a></p>                
            </div>
        </div>
    );
}

export default Register;