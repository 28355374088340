import './Login.css';

import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google-logo.svg';
import { ReactComponent as AppleIcon } from '../../assets/icons/apple-logo.svg';

import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, signInWithApplePopup, signInWithGooglePopup } from "../../infrastructure/helpers/firebase.utils";
import { useNavigate } from 'react-router-dom';
import { FormHelperText } from '@mui/material';
import { validateEmailFormat } from '../../infrastructure/helpers/validation_helper';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailValidationMessage, setEmailValidationMessage] = useState('');
    const [passwordValidationMessage, setPasswordValidationMessage] = useState('');

    const navigate = useNavigate();

    const onGoogleSignIn = () => {
        signInWithGooglePopup().then(x => {
            navigate("/");
        }).catch(err => {

        });
    }

    const onAppleSignIn = () => {
        signInWithApplePopup().then(x => {
            navigate("/");
        }).catch(err => {
            
        });
    }

    const handleForgotPassword = (e) => {
        e.preventDefault();
        navigate("/auth/forgot-password", { state: { email } });
    }

    const validateForm = (email, password) => {
        let isValid = true;

        if(!email || email.trim() === '') {
            setEmailValidationMessage('Email is required');
            isValid = false;
        } else {
            if(!validateEmailFormat(email)) {
                setEmailValidationMessage('Invalid email format');
                isValid = false;
            }
        }        

        if(!password || password.trim() === '') {
            setPasswordValidationMessage('Password is required');
            isValid = false;
        }

        return isValid;
    }

    const submitForm = (e) => {
        e.preventDefault();
        
        if(!validateForm(email, password)) {
            return;
        }

        signInWithEmailAndPassword(auth, email, password)
            .then((e) => {
                navigate("/");
            }).catch(err => {
                console.log(err);
            });  
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value);

        setEmailValidationMessage('');
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);

        setPasswordValidationMessage('');
    }

    return (
        <div>
            <div>
                <Logo />
                <div className='auth-container'>
                    <form onSubmit={submitForm}>
                        <h2 className='title'>Sign in</h2>
                        <TextField
                            id="email"
                            label="Username or email"
                            variant="outlined"
                            fullWidth
                            defaultValue={email}
                            className='text-input'
                            onChange={onEmailChange}
                        />
                        <FormHelperText error={true} style={{textAlign: 'right', marginBottom: '12px'}}>
                            <span dangerouslySetInnerHTML={{__html: emailValidationMessage}}></span>
                        </FormHelperText>
                        <TextField
                            id="password"
                            label="Password"
                            variant="outlined"
                            type="password"
                            fullWidth
                            defaultValue={password}
                            className='text-input'
                            onChange={onPasswordChange}
                        />
                        <FormHelperText error={true} style={{textAlign: 'right', marginBottom: '12px'}}>
                            <span dangerouslySetInnerHTML={{__html: passwordValidationMessage}}></span>
                        </FormHelperText>
                        <button className='btn-blue btn-auth' type='submit'>Sign in</button>
                        <a href="/#" className='login-links' onClick={handleForgotPassword}>Forgot your password?</a>
                        <div className='separator'><span>or</span></div>
                        <button className='btn-white btn-social' onClick={onGoogleSignIn}>
                            <GoogleIcon />
                            <span>Sign in with Google</span>
                        </button>
                        <button className='btn-white btn-social' onClick={onAppleSignIn}>
                            <AppleIcon />
                            <span>Sign in with Apple</span>
                        </button>
                    </form>
                </div>
            </div>
            <div>
                <p className='login-subtitle'>New to AquaPulse? <a className='login-links' href="/auth/register">Create an account</a></p>                
            </div>
        </div>
    );
}

export default Login;