import './TankDropdownComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useAtom } from 'jotai';
import { atom_tanks } from '../../infrastructure/jotai/store';
import { useEffect, useState, useRef } from 'react';
import { Menu, MenuItem } from "@mui/material";

export const TankDropdownComponent = (props) => {
    const { currentTankId, setCurrentTankId, setIsTankChanged } = props;
    const [tank, setTank] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const linkRef = useRef(null);

    const [tanks, ] = useAtom(atom_tanks);

    const handleMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setTank(tanks.find(t => t.id === currentTankId));
    }, [currentTankId, tanks]);

    const handleTankChange = (tankId) => {
        setAnchorEl(null);
        if(currentTankId === tankId) return;

        setCurrentTankId(tankId);
        setIsTankChanged(true);
    }

    return (
        <div className='tank-dropdown-container'>
            <a href='/#' onClick={handleMenu} className="top_fish_tank" ref={linkRef}>
                {tank?.name}
                <FontAwesomeIcon icon={faChevronDown} />
            </a>
            <Menu
                id="tank-dropdown"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                // paper
                // PaperProps={{
                //     style: {
                //       minWidth: linkRef.current ? linkRef.current.offsetWidth : undefined,
                //     },
                // }}
                slotProps={{
                    paper: {
                        sx: {
                            minWidth: linkRef.current ? linkRef.current.offsetWidth + 10 : undefined,
                        }
                    }
                }}
            >
                {tanks.map(t => (
                    <MenuItem key={t.id} onClick={() => handleTankChange(t.id)}>
                        <p className='between-row'>
                            {t.name}
                            {t.id === currentTankId && <FontAwesomeIcon icon={faCheck} color='var(--Blue400)'/>}
                        </p>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}