const firebaseConfig = {
    apiKey: "AIzaSyCnQP91sxP7XfRE0chEz-OyrLfYw8yx04g",
    authDomain: "aquapulse-35104.firebaseapp.com",
    projectId: "aquapulse-35104",
    storageBucket: "aquapulse-35104.appspot.com",
    messagingSenderId: "144424740321",
    appId: "1:144424740321:web:1618d000afbdab231c9ea0"
  };

  export { firebaseConfig };
  