import { useContext } from "react";
import { Context } from "../contexts/AuthContext";
import { Navigate } from "react-router-dom";

const Protected = ({children}) => {
    const { user } = useContext(Context);

    if (!user) {
        return <Navigate to="/auth/login" replace />;
    }

    return children;
}

export default Protected;