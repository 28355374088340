import React, { createContext, useContext, useState, useEffect } from 'react';

import { NotificationComponent } from '../components/Common/NotificationComponent';

const NotificationContext = createContext();
export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (notification) {
      setIsVisible(true); 
      const timeout = setTimeout(() => {
        setIsVisible(false);
        setNotification(null);
      }, 5000);
      return () => clearTimeout(timeout);
    }else {
      setIsVisible(false);
    }
  }, [notification]);

  const showNotification = (message, type) => {
    setNotification({ message, type });
  };

  const hideNotification = () => {
    setNotification(null);
  }

  return (
    <NotificationContext.Provider value={{ showNotification, hideNotification }}>
      {children}
      <div className={`notification-context ${isVisible ? 'show' : ''}`} 
        onAnimationEnd={() => { if (!isVisible) setNotification(null)}}
      >
        {notification && <NotificationComponent message={notification.message} type={notification.type} />}
      </div>
    </NotificationContext.Provider>
  );
};