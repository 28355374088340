import MQTT from 'paho-mqtt';
import { v4 as uuidv4 } from 'uuid';

export default class MqttManager {
  static myInstance = null;

  mqttClient = null;

  constructor() {
    this.mqttClient = new MQTT.Client(
      "mqtt.aquapulse.io",
      443,
      '/mqtt',
      'mbl_' + uuidv4(),
    );
  }

  static getInstance() {
    if (MqttManager.myInstance == null) {
      MqttManager.myInstance = new MqttManager();
    }

    return this.myInstance;
  }
}
