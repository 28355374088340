import './ModuleSummary.css';

import { useEffect, useState } from 'react';
import moment from 'moment';
import { useAtom } from 'jotai';
import { atom_currentLanguage } from '../../infrastructure/jotai/store';

export const StatusIcon = (props) => {
    if(props.isOnline){
        return <span className="status-icon-container status-icon-online"></span>
    } else {
        return <span className="status-icon-container status-icon-offline"></span>
    }
}

export const ModuleSummary = (props) => {
    const { summaryTitle, isOnline, lastUpdated, moduleType, operation, version } = props;
    const [lastUpdatedText, setLastUpdatedText ] = useState();

    const [currentLanguage, ] = useAtom(atom_currentLanguage);
    const [status, setStatus] = useState(isOnline ? 'Online' : 'Offline');

    useEffect(() => {
        setLastUpdatedText(moment(lastUpdated?.lastDataReceived).locale(currentLanguage).fromNow());
    }, [lastUpdated, currentLanguage]);

    useEffect(() => {
        setStatus(isOnline ? 'Online' : 'Offline');
    }, [isOnline]);

    return (
        <div>
            <div className="summary-title">{summaryTitle}</div>
            <div className="summary-details-container row">
                <div className="col">
                    <p className='summary-detail-top' title={status}><StatusIcon isOnline={isOnline}/> {status}</p>
                    <p className='summary-detail-bottom' title='Status'>Status</p>
                </div>

                <div className="col">
                    <p className='summary-detail-top' title={lastUpdatedText}>{lastUpdatedText}</p>
                    <p className='summary-detail-bottom' title='Last Updated'>Last Updated</p>
                </div>

                <div className="col">
                    <p className='summary-detail-top' title={moduleType}>{moduleType}</p>
                    <p className='summary-detail-bottom' title='Module type'>Module type</p>
                </div>

                <div className="col">
                    <p className='summary-detail-top' title={operation}>{operation}</p>
                    <p className='summary-detail-bottom' title='Operation'>Operation</p>
                </div>

                <div className="col">
                    <p className='summary-detail-top' title={version}>{version}</p>
                    <p className='summary-detail-bottom' title='Version'>Version</p>
                </div>
            </div>
        </div>
    );
}