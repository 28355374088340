import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactComponent as AccountIdIcon } from '../../assets/icons/account/account-id.svg';
import { ReactComponent as ResetPasswordIcon } from '../../assets/icons/account/reset-password.svg';
import { ReactComponent as SignOutIcon } from '../../assets/icons/account/sign-out.svg';
import { ReactComponent as DeleteAccountIcon } from '../../assets/icons/account/delete-account.svg';
import { AccountInfoModalComponent } from "../Account/AccountInfoModalComponent";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import { signOut } from "firebase/auth";
import { auth } from "../../infrastructure/helpers/firebase.utils";
import { useState } from "react";
import { ResetPasswordModal } from "../Common/ResetPasswordModal";

export const UserDropDownComponent = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [accountInfoModalOpen, setAccountInfoModalOpen] = useState(false);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        setAnchorEl(null);

        signOut(auth).then(() => {  
            // navigate("/");
        })
    }

    const handleResetPassword = () => {
        setAnchorEl(null);

        setResetPasswordModalOpen(true);
    }

    const handleAccountInfo = () => {
        setAnchorEl(null);

        setAccountInfoModalOpen(true);
    }

    return(
        <div>
            <IconButton
                aria-haspopup="true"
                onClick={handleMenu}
                className="user-icon-container"
            >
                <UserIcon />
            </IconButton>
            <Menu
                id="account-dropdown"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleAccountInfo}><AccountIdIcon />Account info</MenuItem>
                <MenuItem onClick={handleResetPassword}><ResetPasswordIcon />Reset password</MenuItem>
                <MenuItem onClick={handleSignOut}><SignOutIcon />Sign out</MenuItem>
                <MenuItem onClick={handleClose}><DeleteAccountIcon />Delete account</MenuItem>
            </Menu>
            <div>
                <AccountInfoModalComponent accountInfoModalOpen={accountInfoModalOpen} setAccountInfoModalOpen={setAccountInfoModalOpen}/>                
                <ResetPasswordModal resetPasswordModalOpen={resetPasswordModalOpen} setResetPasswordModalOpen={setResetPasswordModalOpen}/>
            </div>
        </div>
    );
}