import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { firebaseConfig } from "../../firebaseConfig";
import { OAuthProvider } from "firebase/auth";



const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({   
    prompt : "select_account "
});

const appleProvider = new OAuthProvider('apple.com');

const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);

const signInWithApplePopup = () => signInWithPopup(auth, appleProvider);

export {auth, googleProvider, appleProvider, signInWithGooglePopup, signInWithApplePopup};