import './AccountInfoModalComponent.css';
import { useRef } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { useAtom } from 'jotai';
import { atom_userData } from '../../infrastructure/jotai/store';
import { Modal, Box, Backdrop, Fade } from '@mui/material';

export const AccountInfoModalComponent = (props) => {
    const { accountInfoModalOpen, setAccountInfoModalOpen } = props;
    const [userData, setUserData] = useAtom(atom_userData);   
    const closeButtonRef = useRef();

    const getCurrentAuthText = (id) => {
        switch (id) {
            case 0:
                return "Email/Password";
            case 1:
                return "Google";
            case 2:
                return "Apple";
        }
        return "";
    }


    return (
        <Modal 
            open={accountInfoModalOpen}
            onClose={() => setAccountInfoModalOpen(false)} 
            className="account-info-modal" 
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={accountInfoModalOpen}>
                <Box className="modal-content">
                    <Box className="modal-body">
                        <Box className='title-modal mg-b-36'>
                            <button className='btn-gray-close mg-r-16' onClick={() => setAccountInfoModalOpen(false)} ref={closeButtonRef}><CloseIcon /></button>
                            Account Info
                        </Box>
                        <div>
                                <div className="account-info-item">
                                    <div className="account-info-item-label">Email address</div>
                                    <div className="account-info-item-value">{userData?.email}</div>
                                </div>
                                <div className="account-info-item">
                                    <div className="account-info-item-label">Full Name</div>
                                    <div className="account-info-item-value">{userData?.fullName}</div>
                                </div>
                                <div className="account-info-item">
                                    <div className="account-info-item-label">Current Authentication</div>
                                    <div className="account-info-item-value">{getCurrentAuthText(userData?.currentAuth)}</div>
                                </div>
                                <div className="account-info-item">
                                    <div className="account-info-item-label">Has Google Auth?</div>
                                    <div className="account-info-item-value">{userData?.hasGoogleAuth ? "Yes" : "No"}</div>
                                </div>
                                <div className="account-info-item">
                                    <div className="account-info-item-label">Has Email/Password Auth</div>
                                    <div className="account-info-item-value">{userData?.hasPassword ? "Yes" : "No"}</div>
                                </div>
                            </div>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}