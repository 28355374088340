import parser from 'cron-parser'

const getCronStringFromScheduleObject = (schedule) => {
    return getCronStringFromSchedule(schedule.enabled, schedule.hour, schedule.minute,
        schedule.d1, schedule.d2, schedule.d3,
        schedule.d4, schedule.d5, schedule.d6, schedule.d7);
}

const getCronStringFromSchedule = (enabled, hour, minute, sunday, monday, tuesday, wednesday, thursday, friday, saturday) => {
    if (enabled === undefined || enabled === null || enabled === false) {
        return "";
    }
    if (hour === -1 || minute === -1) {
        return "";
    }

    if (!sunday && !monday && !tuesday && !wednesday && !thursday && !friday && !saturday) {
        return "";
    }

    let cronStr = "0 0 12 * * 0-6";
    let interval = parser.parseExpression(cronStr);
    let fields = JSON.parse(JSON.stringify(interval.fields));
    let daysArr = [];
    if (sunday) { daysArr.push(0); }
    if (monday) { daysArr.push(1); }
    if (tuesday) { daysArr.push(2); }
    if (wednesday) { daysArr.push(3); }
    if (thursday) { daysArr.push(4); }
    if (friday) { daysArr.push(5); }
    if (saturday) { daysArr.push(6); }

    fields.dayOfWeek = daysArr;
    fields.hour = [hour];
    fields.minute = [minute ?? 0];
    fields.second = [0];

    let modifiedInterval = parser.fieldsToExpression(fields);
    cronStr = modifiedInterval.stringify(true);

    return cronStr;
};

const getDataFromCron = (cronString) => {

    const data =
    {
        enabled: false,
        hour: -1,
        minute: -1,
        d1: false,
        d2: false,
        d3: false,
        d4: false,
        d5: false,
        d6: false,
        d7: false
    };

    if (cronString === null || cronString === undefined) {
        return data;
    }

    cronString.trim();
    if (cronString === "") {
        return data;
    }

    let interval = parser.parseExpression(cronString);
    let daysOfWeek = interval.fields.dayOfWeek

    let minute = interval.fields.minute[0];
    let hour = interval.fields.hour[0];

    data.hour = hour;
    data.minute = minute;

    data.d1 = daysOfWeek.indexOf(0) !== -1;
    data.d2 = daysOfWeek.indexOf(1) !== -1;
    data.d3 = daysOfWeek.indexOf(2) !== -1;
    data.d4 = daysOfWeek.indexOf(3) !== -1;
    data.d5 = daysOfWeek.indexOf(4) !== -1;
    data.d6 = daysOfWeek.indexOf(5) !== -1;
    data.d7 = daysOfWeek.indexOf(6) !== -1;

    data.enabled = true;

    return data;
}

const getDaysFromSchedule = (schedule) => {
    let arr = [
        { selected: schedule.d1, name: 'Sunday' },
        { selected: schedule.d2, name: 'Monday' },
        { selected: schedule.d3, name: 'Tuesday' },
        { selected: schedule.d4, name: 'Wednesday' },
        { selected: schedule.d5, name: 'Thursday' },
        { selected: schedule.d6, name: 'Friday' },
        { selected: schedule.d7, name: 'Saturday' },
    ];
    return arr;
};

export { getDataFromCron, getCronStringFromSchedule, getDaysFromSchedule, getCronStringFromScheduleObject };