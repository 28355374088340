import moment from 'moment';

const getOnlineStatusFromLastDataReceived = (data) => {
    if (data && data?.lastDataReceived) {
        return moment.duration((new moment()).diff(new moment(data?.lastDataReceived))).as('seconds') < 60 ? true : false;
    }
    return false;
}

const getTimeZoneDescriptionTranslated = (currentLanguage, timeZone, timeZonesList) => {
    try {
        let item = timeZonesList.find(x => x.id === timeZone);

        if (item) {
            switch (currentLanguage) {
                case "en":
                    return item.en;
                case "ro":
                    return item.ro;
                case "hu":
                    return item.hu;
                case "tr":
                    return item.tr;
                case "de":
                    return item.de;
                default:
                    return item.en;
            }
        }
    }
    catch (err) {

    }

    return timeZone;
}

const daysArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const DaysOptionsArray = [
    { name: 'Sunday', id: 1 }, 
    { name: 'Monday', id: 2 }, 
    { name: 'Tuesday', id: 3 }, 
    { name: 'Wednesday', id: 4 }, 
    { name: 'Thursday', id: 5 }, 
    { name: 'Friday', id: 6 }, 
    { name: 'Saturday', id: 7 }
];

const getScheduleFrequencyText = (schedule) => {
    let selectedDaysArray = [];

    if (schedule.d1 && schedule.d2 && schedule.d3 && schedule.d4 && schedule.d5 && schedule.d6 && schedule.d7) {
        return "Every day";
    }

    if (schedule.d1) {
        selectedDaysArray.push(daysArray[0]);
    }
    if (schedule.d2) {
        selectedDaysArray.push(daysArray[1]);
    }
    if (schedule.d3) {
        selectedDaysArray.push(daysArray[2]);
    }
    if (schedule.d4) {
        selectedDaysArray.push(daysArray[3]);
    }
    if (schedule.d5) {
        selectedDaysArray.push(daysArray[4]);
    }
    if (schedule.d6) {
        selectedDaysArray.push(daysArray[5]);
    }
    if (schedule.d7) {
        selectedDaysArray.push(daysArray[6]);
    }

    return selectedDaysArray.join(', ');
}

const getScheduleDaysArray = (schedule) => {
    let selectedDaysArray = [];

    if (schedule.d1) {
        selectedDaysArray.push(DaysOptionsArray[0]);
    }
    if (schedule.d2) {
        selectedDaysArray.push(DaysOptionsArray[1]);
    }
    if (schedule.d3) {
        selectedDaysArray.push(DaysOptionsArray[2]);
    }
    if (schedule.d4) {
        selectedDaysArray.push(DaysOptionsArray[3]);
    }
    if (schedule.d5) {
        selectedDaysArray.push(DaysOptionsArray[4]);
    }
    if (schedule.d6) {
        selectedDaysArray.push(DaysOptionsArray[5]);
    }
    if (schedule.d7) {
        selectedDaysArray.push(DaysOptionsArray[6]);
    }

    return selectedDaysArray;
}

const getScheduleDaysSelectedValues = (schedule) => {
    let selectedDaysArray = [];

    if (schedule.d1) {
        selectedDaysArray.push(DaysOptionsArray[0]);
    }
    if (schedule.d2) {
        selectedDaysArray.push(DaysOptionsArray[1]);
    }
    if (schedule.d3) {
        selectedDaysArray.push(DaysOptionsArray[2]);
    }
    if (schedule.d4) {
        selectedDaysArray.push(DaysOptionsArray[3]);
    }
    if (schedule.d5) {
        selectedDaysArray.push(DaysOptionsArray[4]);
    }
    if (schedule.d6) {
        selectedDaysArray.push(DaysOptionsArray[5]);
    }
    if (schedule.d7) {
        selectedDaysArray.push(DaysOptionsArray[6]);
    }

    return selectedDaysArray;
}

export { getOnlineStatusFromLastDataReceived, 
         getTimeZoneDescriptionTranslated, 
         getScheduleFrequencyText, 
         getScheduleDaysSelectedValues, 
         getScheduleDaysArray,
         DaysOptionsArray };