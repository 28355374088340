import './NotificationComponent.css';

import { ReactComponent as WhiteCloseIcon } from '../../assets/icons/close-white.svg';

import { useNotification } from '../../contexts/NotificationContext';

export const NotificationComponent = ({ message, type }) => {
    const { hideNotification } = useNotification();

    const onCloseClick = () => {
        hideNotification();
    }

    return (
        <div className='notification-container-outer'>
            <div className='notification-container container'>
                <div className={`global-notification ${type}`}>
                    <p className='mg-b-0'>{message}</p>
                    <div className='notification-close-container' onClick={onCloseClick}>
                        <WhiteCloseIcon className='notification-close'/>
                    </div>
                </div>
            </div>
        </div>
    );
};