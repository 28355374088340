import './CurrentTimeComponent.css';

import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';

export const CurrentTimeComponent = ({ tz, style }) => {

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        let tm = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => {
            clearInterval(tm);
        }
    }, []);

    return (
        // <Moment format='HH:mm' element={Text} tz={tz} >{currentTime}</Moment>
        <Moment format='HH:mm' tz={tz} >{currentTime}</Moment>
    )
}