import './RemoveModuleModalComponent.css';
import { useRef } from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import { getAuth } from 'firebase/auth';
import { api_delete_device } from '../../infrastructure/api/server_calls';
import { sendMqttData } from '../../infrastructure/helpers/mqtt_sender';
import { mqttMessageTypes } from '../../infrastructure/helpers/mqtt_protocol';
import { useAtom } from 'jotai';
import { atom_devices } from '../../infrastructure/jotai/store';

export const RemoveModuleModalComponent = (props) => {
    const { showNotification, id } = useNotification();
    const closeButtonRef = useRef(null);
    const auth = getAuth();
    const [, setDeviceList] = useAtom(atom_devices);

    const removeModule = () => {
        auth.currentUser.getIdToken().then(token => {
            api_delete_device(token, id).then(resp => {
                if (resp.success) {
                    let dt = resp.data;
                    if (dt.success === true) {
                        setDeviceList(state => {
                            return state.filter(x => !x.id.startsWith(id));
                        })
                        sendMqttData(
                            id,
                            JSON.stringify({
                                type: mqttMessageTypes.MQTT_MSG_TYPE_FACTORY_RESET_REQUEST,
                                deviceId: id,
                                payload: null,
                            }),
                        );
                    }
                }
                showNotification('Module successfully removed.', 'success');
                if(closeButtonRef.current){
                    closeButtonRef.current.click();
                }
            })
            .catch(error => {
                showNotification('Error removing module. Please try again.', 'error');
            });
        }).catch((error) => {
            showNotification('Error removing module. Please try again.', 'error');
        });

        
    }

    return (
        <div className="modal remove-module-modal fade" tabIndex="-1" id="remove-module-modal" data-bs-backdrop="static">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className='title-modal mg-b-36'>
                            Remove module
                        </div>
                        <div>
                            <p className='remove-text'>If you remove the module, you’ll lose access to it.</p>
                        </div>
                        <div className='row-end'>
                            <button className='btn-gray' data-bs-dismiss="modal" ref={closeButtonRef}>Keep module</button>
                            <button className='btn-red mg-l-8' onClick={removeModule}>Remove</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}