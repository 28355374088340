import React from 'react';

import { deviceTypeEnum, devicePanelScreenTypesEnum } from './device_types';
import { RtcModule } from '../../pages/RtcModule';

const LightsModule = React.lazy(() => import('../../pages/LightsModule'));
const RelaySocketModule = React.lazy(() => import('../../pages/RelaySocketModule'));
const FeederModule = React.lazy(() => import('../../pages/FeederModule'));
const TemperatureModule = React.lazy(() => import('../../pages/TemperatureModule'));
const WaterQualityModule = React.lazy(() => import('../../pages/WaterQualityModule'));
// const DosingPumpModule = React.lazy(() => import('../../pages/DosingPumpModule'));

const modules = [
    { 
        deviceType: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM,
        devicePanelScreenType: devicePanelScreenTypesEnum.temperature, 
        render: (currentDeviceId) => (
            <TemperatureModule 
                key={`temperature-${currentDeviceId}`}
                currentDeviceId={currentDeviceId} 
                deviceType={deviceTypeEnum.WATER_QUALITY_PANEL_ITEM} 
                devicePanelScreenType={devicePanelScreenTypesEnum.temperature}/>
        ) 
    },
    { 
        deviceType: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM, 
        devicePanelScreenType: devicePanelScreenTypesEnum.ph, 
        render: (currentDeviceId) => (
            <WaterQualityModule 
                key={`ph-${currentDeviceId}`} 
                currentDeviceId={currentDeviceId} 
                deviceType={deviceTypeEnum.WATER_QUALITY_PANEL_ITEM} 
                devicePanelScreenType={devicePanelScreenTypesEnum.ph}/>
        ) 
    },
    { 
        deviceType: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM, 
        devicePanelScreenType: devicePanelScreenTypesEnum.ec, 
        render: (currentDeviceId) => (
            <WaterQualityModule 
                key={`ec-${currentDeviceId}`} 
                currentDeviceId={currentDeviceId} 
                deviceType={deviceTypeEnum.WATER_QUALITY_PANEL_ITEM} 
                devicePanelScreenType={devicePanelScreenTypesEnum.ec}/> 
        )
    },
    {
        deviceType: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM,
        devicePanelScreenType: devicePanelScreenTypesEnum.tds, 
        render: (currentDeviceId) => (
            <WaterQualityModule 
                key={`tds-${currentDeviceId}`} 
                currentDeviceId={currentDeviceId} 
                deviceType={deviceTypeEnum.WATER_QUALITY_PANEL_ITEM} 
                devicePanelScreenType={devicePanelScreenTypesEnum.tds} /> 
        )
    },
    {
        deviceType: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM,
        devicePanelScreenType: devicePanelScreenTypesEnum.sg, 
        render: (currentDeviceId) => (
            <WaterQualityModule 
                key={`sg-${currentDeviceId}`} 
                currentDeviceId={currentDeviceId} 
                deviceType={deviceTypeEnum.WATER_QUALITY_PANEL_ITEM} 
                devicePanelScreenType={devicePanelScreenTypesEnum.sg} /> 
        )
    },
    {
        deviceType: deviceTypeEnum.FEEDER,
        render: (currentDeviceId) => (
            <FeederModule 
                key={`feeder-${currentDeviceId}`}
                currentDeviceId={currentDeviceId} 
                deviceType={deviceTypeEnum.FEEDER}/> 
        )
    }, 
    { 
        deviceType: deviceTypeEnum.LIGHTS,
        render: (currentDeviceId) => (
            <LightsModule 
                key={`lights-${currentDeviceId}`}
                currentDeviceId={currentDeviceId} 
                deviceType={deviceTypeEnum.LIGHTS}/> 
        )
    },
    {
        deviceType: deviceTypeEnum.LIGHTS_RGB,
        render: (currentDeviceId) => (
            <LightsModule 
                key={`lights-rgb-${currentDeviceId}`}
                currentDeviceId={currentDeviceId} 
                deviceType={deviceTypeEnum.LIGHTS}/> 
        )
    },
    {
        deviceType: deviceTypeEnum.LED_CONTROLLER_1_CHANNEL,
        render: (currentDeviceId) => (
            <LightsModule 
                key={`led-1-channel-${currentDeviceId}`}
                currentDeviceId={currentDeviceId} 
                deviceType={deviceTypeEnum.LIGHTS}/> 
        )
    },       
    {
        deviceType: deviceTypeEnum.POWER_STRIP_3_SOCKET,
        render: (currentDeviceId) => (
            <RelaySocketModule 
                key={`ps-3-socket-${currentDeviceId}`}
                currentDeviceId={currentDeviceId}
                deviceType={deviceTypeEnum.POWER_STRIP_3_SOCKET}/> 
        )
    },
    {
        deviceType: deviceTypeEnum.POWER_STRIP_4_SOCKET,
        render: (currentDeviceId) => (
            <RelaySocketModule 
                key={`ps-4-socket-${currentDeviceId}`}
                currentDeviceId={currentDeviceId}
                deviceType={deviceTypeEnum.POWER_STRIP_4_SOCKET}/> 
        )
    },
    {
        deviceType: deviceTypeEnum.POWER_STRIP_6_SOCKET,
        render: (currentDeviceId) => (
            <RelaySocketModule 
                key={`ps-6-socket-${currentDeviceId}`}
                currentDeviceId={currentDeviceId}
                deviceType={deviceTypeEnum.POWER_STRIP_6_SOCKET}/> 
        )
    },
    {
        deviceType: deviceTypeEnum.COOLER_CONTROLLER,
        render: (currentDeviceId) => (
            <TemperatureModule 
                key={`cooler-${currentDeviceId}`}
                currentDeviceId={currentDeviceId}
                deviceType={deviceTypeEnum.COOLER_CONTROLLER}/> 
        )
    },
    {
        deviceType: deviceTypeEnum.DOSING_PUMP,
        render: (currentDeviceId) => (
            <FeederModule 
                key={`dosing-pump-${currentDeviceId}`}
                currentDeviceId={currentDeviceId}
                deviceType={deviceTypeEnum.DOSING_PUMP}/> 
        )
    },
    {
        deviceType: deviceTypeEnum.RTC,
        render: (currentDeviceId) => (
            <RtcModule 
                key={`rtc-${currentDeviceId}`}
                currentDeviceId={currentDeviceId}
                deviceType={deviceTypeEnum.RTC}/> 
        )
    }
]

export { modules };