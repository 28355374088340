import React from "react";
import './infrastructure/helpers/firebase.utils';
import { MainLayout } from "./layouts/MainLayout";
import { NotificationProvider } from './contexts/NotificationContext';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthLayout } from "./layouts/AuthLayout";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import AuthContext from "./contexts/AuthContext";
import Protected from "./routes/Protected";

function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Protected><MainLayout /></Protected>
    },
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        {
          index: true,
          element: <Login />,
        },
        {
          path: 'login',
          element: <Login />,
        },        
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: 'register',
          element: <Register />
        }
      ],
    }
  ]);

  return (
    <AuthContext>
      <NotificationProvider>
        <RouterProvider router={router} />
      </NotificationProvider>
    </AuthContext>
  );
}

export default App;
