import { devicePanelScreenTypesEnum, deviceTypeEnum } from "./device_types";

const processDevicesFullData = (devices, names, orders, sockets) => {

    let devicesMainList = devices.filter(x => x.type !== deviceTypeEnum.WATER_QUALITY_5IN1 && x.type !== deviceTypeEnum.WATER_QUALITY_8IN1).map(x => {
        return { ...x, deviceId: x.id, screenVisible: true };
    });
    let arrWithWaterQuality = devices.filter(x => x.type === deviceTypeEnum.WATER_QUALITY_5IN1 || x.type === deviceTypeEnum.WATER_QUALITY_8IN1).map(x => {
        return { ...x, deviceId: x.id, screenVisible: false };
    });

    if (arrWithWaterQuality.length !== 0) {
        for (var i = 0; i < arrWithWaterQuality.length; i++) {
            let item = arrWithWaterQuality[i];
            let devId = item.id;
            devicesMainList.push({ ...item });

            let pnlType = devicePanelScreenTypesEnum.ph;
            devicesMainList.push({ id: devId + '-' + pnlType, deviceId: devId, panelScreenType: pnlType, type: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM, tankId: item.tankId, name: 'PH', screenVisible: true });
            pnlType = devicePanelScreenTypesEnum.ec;
            devicesMainList.push({ id: devId + '-' + pnlType, deviceId: devId, panelScreenType: pnlType, type: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM, tankId: item.tankId, name: 'EC', screenVisible: true });
            pnlType = devicePanelScreenTypesEnum.tds;
            devicesMainList.push({ id: devId + '-' + pnlType, deviceId: devId, panelScreenType: pnlType, type: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM, tankId: item.tankId, name: 'TDS', screenVisible: true });
            pnlType = devicePanelScreenTypesEnum.sg;
            devicesMainList.push({ id: devId + '-' + pnlType, deviceId: devId, panelScreenType: pnlType, type: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM, tankId: item.tankId, name: 'SG', screenVisible: true });
            pnlType = devicePanelScreenTypesEnum.temperature;
            devicesMainList.push({ id: devId + '-' + pnlType, deviceId: devId, panelScreenType: pnlType, type: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM, tankId: item.tankId, name: 'Temperature', screenVisible: true });

            if (item.type === deviceTypeEnum.WATER_QUALITY_8IN1) {
                pnlType = devicePanelScreenTypesEnum.orp;
                devicesMainList.push({ id: devId + '-' + pnlType, deviceId: devId, panelScreenType: pnlType, type: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM, tankId: item.tankId, name: 'ORP', screenVisible: true });
                pnlType = devicePanelScreenTypesEnum.salt;
                devicesMainList.push({ id: devId + '-' + pnlType, deviceId: devId, panelScreenType: pnlType, type: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM, tankId: item.tankId, name: 'Salt', screenVisible: true });
                pnlType = devicePanelScreenTypesEnum.cf;
                devicesMainList.push({ id: devId + '-' + pnlType, deviceId: devId, panelScreenType: pnlType, type: deviceTypeEnum.WATER_QUALITY_PANEL_ITEM, tankId: item.tankId, name: 'CF', screenVisible: true });
            }
        }
    }

    if (devicesMainList.length > 0) {
        for (var i = 0; i < devicesMainList.length; i++) {
            let orderData = orders.find(x => x.id === devicesMainList[i].id);
            if (orderData) {
                devicesMainList[i].orderIndex = orderData.orderIndex;
            }
            let nameData = names.find(x => x.id === devicesMainList[i].id);
            if (nameData) {
                devicesMainList[i].name = nameData.name;
            }
            let socketNameData = sockets.find(x => x.id === devicesMainList[i].id);
            if (socketNameData) {
                devicesMainList[i].socket1Name = socketNameData.socket1Name;
                devicesMainList[i].socket2Name = socketNameData.socket2Name;
                devicesMainList[i].socket3Name = socketNameData.socket3Name;
                devicesMainList[i].socket4Name = socketNameData.socket4Name;
            }
        }
    }

    return devicesMainList;
    
    
}

export { processDevicesFullData };
