import MqttManager from "./mqtt_helper";
import { mqttMessageTypes } from "./mqtt_protocol";

const _mqttClient = MqttManager.getInstance().mqttClient;

const sendMqttData = (topic, jsonData) => {

  if (_mqttClient && _mqttClient.isConnected()) {
    // dl('Sending mqtt data:');
    // dl(jsonData);
    _mqttClient.send(
      topic,
      jsonData,
    );
    return true;
  }
  return false;
};

const subscribeToMqttTopic = (topic) => {

  try {
    _mqttClient.subscribe(topic);
    // mqttClient.subscribe("_diag");
    // dl('subscribed to ' + topic);

  } catch (err) {
    // dl(err);
  }


}

const sendMqttPingToTankId = (tankId) => {
  if (tankId) {
    if (_mqttClient) {
      // dl('PING to DEVICES');
        sendMqttData(tankId,
          JSON.stringify({
            type: mqttMessageTypes.MQTT_MSG_TYPE_MOBILE_OR_WEB_PING_DEVICE,
            payload: null,
          }),
        );
    }
  }
}

const sendMqttPingToOrganizationId = (organizationId, force = false) => {
  if (organizationId) {
    if (_mqttClient) {
      // dl('PING to DEVICES');
        sendMqttData(organizationId,
          JSON.stringify({
            type: force ?  mqttMessageTypes.MQTT_MSG_TYPE_MOBILE_OR_WEB_PING_DEVICE_FORCED : mqttMessageTypes.MQTT_MSG_TYPE_MOBILE_OR_WEB_PING_DEVICE,
            payload: null,
          }),
        );
    }
  }
}

export { sendMqttData, subscribeToMqttTopic, sendMqttPingToOrganizationId, sendMqttPingToTankId };