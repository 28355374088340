import './DownloadsComponent.css';

import { ReactComponent as DownloadIcon } from '../../assets/icons/pdf.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg';

import { NavLink } from "react-router-dom";

export const DownloadsComponent = (props) => {
    const { link, title, size } = props;
    return (
        <div className='download-container row'>
            <div>
                <h5 className='module-title downloads-title mg-b-24'>
                    <span className='center-row g-8'>Downloads</span>
                </h5>
                <NavLink className='manual-container' to={link}>
                    <div className='manual-container-left'>
                        <div className='pdf-icon-container'>
                            <DownloadIcon />
                        </div>                    
                        <div className='download-title-size-container'>
                            <span className='download-title'>{title}</span>
                            <span className='download-size'>{size}</span>
                        </div>
                    </div>
                    <RightArrowIcon />
                </NavLink>
            </div>
        </div>
    );
}