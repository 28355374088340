import './RenameModuleModalComponent.css';

import { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { getAuth } from 'firebase/auth';
import { useNotification } from '../../contexts/NotificationContext';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { api_update_device_screen_name } from '../../infrastructure/api/server_calls';
import { useAtom } from 'jotai';
import { atom_devices } from '../../infrastructure/jotai/store';

export const RenameModuleModalComponent = (props) => {
    const { moduleName, id } = props;
    const { showNotification } = useNotification();
    const auth = getAuth();
    const [moduleNameInput, setModuleNameInput] = useState("");
    const [isInitialized, setIsInitialized] = useState(false);
    const [, setDeviceList] = useAtom(atom_devices);
    const closeButtonRef = useRef(null);

    useEffect(() => {
        if(isInitialized || !moduleName) return;
        
        setModuleNameInput(moduleName);
        setIsInitialized(true);
    }, [moduleName, isInitialized]);

    const renameModule = () => {
        if(!moduleNameInput.trim()) return;

        if(moduleNameInput !== moduleName){
            auth.currentUser.getIdToken().then(token => {
                api_update_device_screen_name(token, moduleNameInput, id).then(response => {
                    if(response.success){
                        setDeviceList(state => {
                            return state.map(item => {
                                if(item.id === id){
                                    return {...item, name: moduleNameInput};
                                }
                                return item;
                            }                                
                        )});
                    }
                })
            }).catch((error) => {
                showNotification('Error renaming module. Please try again.', 'error');
            });
        }

        showNotification('Module successfully renamed.', 'success');
        if(closeButtonRef.current){
            closeButtonRef.current.click();
        }
    }

    return (
        <div className="modal rename-module-modal fade" tabIndex="-1" id="rename-module-modal" data-bs-backdrop="static">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className='title-modal mg-b-36'>
                            <button className='btn-gray-close mg-r-16' data-bs-dismiss="modal" ref={closeButtonRef}><CloseIcon /></button>
                            Rename module
                        </div>
                        <div className='mg-b-24'>
                            <TextField
                                id="module-name"
                                label="Module name"
                                variant="outlined"
                                fullWidth
                                value={moduleNameInput}
                                onChange={(e) => setModuleNameInput(e.target.value)}
                                required
                                error={!moduleNameInput.trim()}
                                helperText={!moduleNameInput.trim() ? 'Module name is required' : ''}
                            />
                        </div>
                        <div className='row-end'>
                            <button className='btn-blue mg-l-8' onClick={renameModule}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}