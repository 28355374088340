import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

function getItem(key) {
    const value = localStorage.getItem(key);
    return value ? value : null;
  }
  
  function setItem(key, value) {
    localStorage.setItem(key, value);
  }
  
  function removeItem(key) {
    localStorage.removeItem(key);
  }
  
  function clearAll() {
    localStorage.clear();
  }

  const atomWithLocalStorage = (key, initialValue) =>
    atomWithStorage(
      key,
      initialValue,
      createJSONStorage(() => ({
        getItem,
        setItem,
        removeItem,
        clearAll,
      })),
      { getOnInit: true }
    );

const atom_userData = atomWithLocalStorage("_user_data_", null);
const atom_currentLanguage = atomWithLocalStorage("_current_lang_", "en");
const atom_feederPumpLastActions = atomWithLocalStorage("_feederPumpLastActions_", []);
const atom_devices = atomWithLocalStorage("_devices_", []);
const atom_firmwareList = atomWithLocalStorage("_firmwareList_", []);
const atom_myNotifications = atomWithLocalStorage("_myNotifications_");
const atom_mySettings = atomWithLocalStorage("_mySettings_", {});
const atom_notificationSettings = atomWithLocalStorage("_noticationSettings_", []);
const atom_tanks = atomWithLocalStorage("_tanks_", []);
const atom_timeZonesList = atomWithLocalStorage("_timeZonesList_", []);

const atom_deviceLastUpdates = atom([]);
const atom_deviceValues = atom([]);
const atom_firebaseStatus = atom(false);
const atom_mobileDeviceToken = atom({ token: "" });
const atom_mqttConnectionStatus = atom(false);
const atom_forceDevicesListUpdate = atom(null);

const atom_currentTankId = atomWithLocalStorage("_currentTankId_", null);

export {
    atom_currentLanguage,
    atom_deviceLastUpdates, atom_deviceValues, atom_devices, atom_feederPumpLastActions,
    atom_firebaseStatus,
    atom_firmwareList, atom_forceDevicesListUpdate, atom_mobileDeviceToken,
    atom_mqttConnectionStatus,
    atom_myNotifications,
    atom_mySettings,
    atom_notificationSettings,
    atom_tanks,
    atom_timeZonesList, 
    atom_userData,
    atom_currentTankId,
};
