/* eslint-disable no-undef */
import axios from 'axios';
import { AQUAPULSE_API_URL } from '../aquapulse_constants';


const mainUrl = AQUAPULSE_API_URL;
let demoUrl = 'https://demomodules.aquapulse.io/api';

const abortTimeOut = 3000;

const api_register_demo_module = (requestData) => {
  return new Promise((resolve) => {
    api_post_demo_app('/add', {...requestData, token: '2db32b31-ee6b-47d5-abc5-b1f1226bd4fc'})
      .then(response => {
        if (response.status === 200) {
          const data = response.data;
          resolve({ success: true, data: data });
        }
        else {
          resolve({ success: false, error: { httpCode: response.status } });
        }
      })
      .catch(err => {
        resolve({ success: false, error: err });
      });
  });
}

const api_post_demo_app = (url, requestData) => {

  let abortController = new AbortController();
  setTimeout(() => {
    abortController.abort();
  }, abortTimeOut);

  return axios.post(
    demoUrl + url,
    requestData ?? {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: abortTimeOut,
      signal: abortController.signal,
    },
  );
}

const api_post = (url, token, requestData) => {
  let abortController = new AbortController();
  setTimeout(() => {
    abortController.abort();
  }, abortTimeOut);

  if (!token) {
    return axios.post(
      mainUrl + url,
      requestData ?? {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: abortTimeOut,
        signal: abortController.signal,
      },
    );  
  }

  return axios.post(
    mainUrl + url,
    requestData ?? {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: abortTimeOut,
      signal: abortController.signal,
    },
  );
}

const api_get = (url, token) => {
  let abortController = new AbortController();
  setTimeout(() => {
    abortController.abort();
  }, abortTimeOut);
  if (token) {
    return axios.get(mainUrl + url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: abortTimeOut,
      signal: abortController.signal,
    });
  }

  return axios.get(mainUrl + url, {
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: abortTimeOut,
    signal: abortController.signal,
  });
  
};

const api_get_with_promise = (url, token) => {
  return new Promise((resolve) => {
    api_get(url, token)
      .then(response => {
        if (response.status === 200) {
          const data = response.data;
          resolve({ success: true, data: data });
        }
        else {
          resolve({ success: false, error: { httpCode: response.status } });
        }
      })
      .catch(err => {
        resolve({ success: false, error: err });
      });
  });
}


const api_post_with_promise = (url, token, requestData) => {
  return new Promise((resolve) => {
    api_post(url, token, requestData)
      .then(response => {
        if (response.status === 200) {
          const data = response.data;
          resolve({ success: true, data: data });
        }
        else {
          resolve({ success: false, error: { httpCode: response.status } });
        }
      })
      .catch(err => {
        resolve({ success: false, error: err });
      });
  });
}

const api_get_all_user_data = (token, tz, locale) => {
  return api_post_with_promise('/user/data', token, { timeZone: tz, locale: locale });
};

const api_user_check = (token, tz) => {
  return api_post_with_promise('/user/check', token, { timeZone: tz });
};

const api_get_doser_chart_data = (token, timeZone, deviceId) => {
  return api_post_with_promise('/devices/getDoserChartData', token, { deviceId: deviceId, timeZone: timeZone });
};

const api_get_feeder_chart_data = (token, timeZone, deviceId) => {
  return api_post_with_promise('/devices/getFeederChartData', token, { deviceId: deviceId, timeZone: timeZone });
};

const api_get_lights_chart_data = (token, timeZone, deviceId, queryType) => {
  return api_post_with_promise('/devices/getLightsChartData', token, { deviceId: deviceId, timeZone: timeZone, queryType: queryType });
};

const api_get_waterQuality_chart_data = (token, timeZone, deviceId, type, queryType) => {
  return api_post_with_promise('/devices/getWaterQualityChartData', token, { deviceId: deviceId, timeZone: timeZone, queryType: queryType, type: type });
};

const api_delete_device = (token, deviceId) => {
  return api_post_with_promise('/devices/delete', token, { id: deviceId });
};

const api_delete_tank = (token, tankId) => {
  return api_post_with_promise('/tanks/delete', token, { id: tankId });
};

const api_add_tank = (token, tankName, tz) => {
  return api_post_with_promise('/tanks/add', token, { name: tankName, timeZone: tz });
};

const api_update_device_screen_order = (token, items) => { //items = [{id, orderIndex}]
  return api_post_with_promise('/user/updateDeviceScreenOrder', token, { items: items });
};

const api_update_device_screen_name = (token, name, deviceScreenId) => {
  return api_post_with_promise('/user/updateDeviceScreenName', token, { name: name, deviceScreenId: deviceScreenId });
};

const api_set_temperature_unit = (token, temperatureUnit) => {
  return api_post_with_promise('/user/updateTemperatureUnit', token, { value: temperatureUnit });
};

const api_get_device_reg_token = token => {
  return api_get_with_promise('/devices/getRegToken', token);
};

const api_get_timeZones = token => {
  return api_get_with_promise('/time/list', token);
};

const api_update_device_power_socket_name = (token, name, socketNum, deviceId) => {
  return api_post_with_promise('/user/updatePowerSocketName', token, { name: name, socketNum: socketNum, deviceId: deviceId });
};

const api_update_notification_settings = (token, updateModel) => { //items = [{id, orderIndex}]
  return api_post_with_promise('/user/updateNotificationSettings', token, { ...updateModel });
};

const api_rename_tank = (token, tankId, tankName) => {
  return api_post_with_promise('/tanks/updateName', token, { name: tankName, id: tankId });
};

const api_delete_mobile_messaging_id = (token, id) => {
  return api_post_with_promise('/devices/deleteMobileDeviceMessagingId', token, { id: id });
};

const api_delete_my_account = (token) => {
  return api_post_with_promise('/user/deleteMyAccount', token, { });
};

const api_set_password = (token, pwd) => {
  return api_post_with_promise('/user/setPassword', token, { password: pwd  });
};

const api_send_verification_email = (token) => {
  return api_post_with_promise('/user/sendVerifyEmailCode', token, { });
};

const api_verify_email_code = (token, code) => {
  return api_post_with_promise('/user/verifyEmailCode', token, { code: code });
};

const api_get_timezones = () => {
  return api_get_with_promise('/time/getTimeZonesListJson');
};

const daySearchTypeEnum = {
  today: 0,
  yesterday: 1
};

const chartWaterQualityValueTypeEnum = {
  none: 0,
  ec: 1,
  ph: 2,
  sg: 3,
  tds: 4,
  temperature: 5,
  orp: 6,
  waterflow: 7,
  dissolvedoxygen: 8,
  turbidity: 9,
  salt: 10,
  cf: 11
};

const serverChartTypeEnum = {
  temperature: 0,
  ph: 1,
  tds: 2,
  sg: 3,
  ec: 4,
  orp: 5,
  waterflow: 6,
  dissolvedoxygen: 7,
  turbidity: 8,
  salt: 9,
  cf: 10,
}

const convertChartTypeToServerType = (type) => {
  switch (type) {
    case chartWaterQualityValueTypeEnum.none: 
      return -1;
      case chartWaterQualityValueTypeEnum.ec: 
      return serverChartTypeEnum.ec;
      case chartWaterQualityValueTypeEnum.ph: 
      return serverChartTypeEnum.ph;
      case chartWaterQualityValueTypeEnum.sg: 
      return serverChartTypeEnum.sg;
      case chartWaterQualityValueTypeEnum.tds: 
      return serverChartTypeEnum.tds;
      case chartWaterQualityValueTypeEnum.temperature: 
      return serverChartTypeEnum.temperature;
      case chartWaterQualityValueTypeEnum.orp: 
      return serverChartTypeEnum.orp;
      case chartWaterQualityValueTypeEnum.waterflow: 
      return serverChartTypeEnum.waterflow;
      case chartWaterQualityValueTypeEnum.dissolvedoxygen: 
      return serverChartTypeEnum.dissolvedoxygen;
      case chartWaterQualityValueTypeEnum.turbidity: 
      return serverChartTypeEnum.turbidity;
      case chartWaterQualityValueTypeEnum.salt: 
      return serverChartTypeEnum.salt;
      case chartWaterQualityValueTypeEnum.cf: 
      return serverChartTypeEnum.cf;
      default:
        return -1;
  }
}

export {
  api_user_check,
  api_get_all_user_data,
  api_get_doser_chart_data,
  api_get_feeder_chart_data,
  api_get_lights_chart_data,
  api_get_waterQuality_chart_data,
  api_delete_device,
  api_delete_tank,
  api_add_tank,
  api_update_device_screen_order,
  api_update_device_screen_name,
  api_set_temperature_unit,
  api_get_device_reg_token,
  api_get_timeZones,
  api_update_device_power_socket_name,
  api_update_notification_settings,
  api_rename_tank,
  api_delete_mobile_messaging_id,
  api_delete_my_account,
  api_set_password,
  api_send_verification_email,
  api_verify_email_code,
  api_register_demo_module,
  api_get_timezones,


  convertChartTypeToServerType,
  daySearchTypeEnum,
  chartWaterQualityValueTypeEnum,
  serverChartTypeEnum,
};
