import { useEffect, useState } from "react";
import { getOnlineStatusFromLastDataReceived } from "./time_helper";
import { devicePanelScreenTypesEnum, deviceTypeEnum } from "./device_types";
import { getTemperatureByUnit } from "./device_types";
import { getDataFromCron } from "./cron_helpers";

export const GetOnlineStatus = (deviceId, deviceLastUpdates) => {
    const [lastDataReceived, setLastDataReceived] = useState();
    const [isOnline, setIsOnline] = useState(getOnlineStatusFromLastDataReceived(lastDataReceived));    

    useEffect(() => {
        setIsOnline(getOnlineStatusFromLastDataReceived(lastDataReceived));
        let tm = setInterval(() => {
            setIsOnline(getOnlineStatusFromLastDataReceived(lastDataReceived));
        }, 1000);

        return () => {
            if (tm) {
                clearInterval(tm);
            }
        };
    }, [lastDataReceived]);

    useEffect(() => {
        if(!deviceLastUpdates) return;
        let val = deviceLastUpdates.find(x => x.id === deviceId) ?? {};
        setLastDataReceived(val);
    }, [deviceId, deviceLastUpdates]);

    return isOnline;
}

export const GetDevice = (deviceId, devices, devicePanelScreenType) => {
    const [device, setDevice] = useState();

    useEffect(() => {
        let currentDevice = devices.find(x => x.deviceId === deviceId && (devicePanelScreenType ? x.panelScreenType === devicePanelScreenType : true));
        setDevice(currentDevice);
    }, [deviceId, devices, devicePanelScreenType]);

    return device;
}

export const GetDeviceValue = ({device, valuesList, deviceType, devicePanelScreenType, feederPumpLastActions, userData}) => {
    const [value, setValue] = useState({temperature: '', coolerStatus: false, coolerTarget: '', value: ''});

    useEffect(() => {
        if(!device || !valuesList) return;
        const currentDeviceValue = valuesList.find(x => x.id === device.deviceId);

        switch(deviceType){
            case deviceTypeEnum.WATER_QUALITY_PANEL_ITEM:
                const generalWaterValues = valuesList.find(x => x.id === device.deviceId);

                switch(devicePanelScreenType){
                    case devicePanelScreenTypesEnum.temperature:                        
                        if(userData && currentDeviceValue){
                            const coolerStatus = currentDeviceValue.coolerTarget > 0;
                            const coolerTarget = getTemperatureByUnit(currentDeviceValue.coolerTarget, userData.temperatureUnit);
                            const temperature = getTemperatureByUnit(currentDeviceValue.temperature, userData.temperatureUnit);
                            setValue({temperature: temperature, coolerStatus: coolerStatus, coolerTarget: coolerTarget});
                        }
                        break;
                    case devicePanelScreenTypesEnum.ph:
                    case devicePanelScreenTypesEnum.ec:
                    case devicePanelScreenTypesEnum.tds:
                    case devicePanelScreenTypesEnum.sg:
                        setValue({value: generalWaterValues});                        
                        break;
                    default:
                        break;
                }
                break;
            case deviceTypeEnum.LIGHTS:
            case deviceTypeEnum.LIGHTS_RGB:
            case deviceTypeEnum.LED_CONTROLLER_1_CHANNEL:
                if(currentDeviceValue){
                    setValue({value: currentDeviceValue.currentLightsPercentage});
                }
                break;
            case deviceTypeEnum.FEEDER:
            case deviceTypeEnum.DOSING_PUMP:
                if(feederPumpLastActions){
                    const feederValue = feederPumpLastActions.find(x => x.id === device.deviceId);
                    if(feederValue){
                        setValue({value: feederValue.timeStamp});
                    }
                }
                break;
            case deviceTypeEnum.POWER_STRIP_3_SOCKET:
            case deviceTypeEnum.POWER_STRIP_4_SOCKET:
            case deviceTypeEnum.POWER_STRIP_6_SOCKET:
                if(currentDeviceValue){
                    setValue({value: currentDeviceValue});
                }
                break;
            case deviceTypeEnum.COOLER_CONTROLLER:
                if(currentDeviceValue){
                    setValue({value: currentDeviceValue});
                }
                break;
            case deviceTypeEnum.RTC:
                if(currentDeviceValue){
                    setValue({value: currentDeviceValue});
                }
                break;
            default:
                break;
        }
    }, [device, valuesList, deviceType, devicePanelScreenType, feederPumpLastActions, userData]);

    return value;
}

export const GetWateQualityValueText = (waterValue, devicePanelScreenType) => {
    const [valueText, setValueText] = useState('');

    useEffect(() => {
        if(waterValue) {
            switch(devicePanelScreenType){
                case devicePanelScreenTypesEnum.ph:
                    if(waterValue.ph) 
                        setValueText((waterValue.ph / 100).toFixed(1));
                    break;
                case devicePanelScreenTypesEnum.ec:
                    if(waterValue.ec)
                        setValueText(<>{waterValue.ec.toString()} <span className="module-title-small-text"> us/cm</span></>);
                    break;
                case devicePanelScreenTypesEnum.tds:
                    if(waterValue.tds)
                        setValueText(<>{waterValue.tds.toString()}<span className="module-title-small-text"> ppm</span></>);
                    break;
                case devicePanelScreenTypesEnum.sg:
                    if(waterValue.sg)
                        setValueText((waterValue.sg / 1000).toFixed(3).toString().replace(/^0+/, ''));
                    break;
                default:
                    break;
            }
        }        
    }, [waterValue, devicePanelScreenType]);

    return valueText;
}

export const getScheduleFromValues = (schedule, scheduleTime, scheduleSelectedValues) => {
    let hour = scheduleTime?.hours() ?? -1;
    let minute = scheduleTime?.minutes() ?? -1;
    if(!schedule.enabled){
        hour = -1;
        minute = -1;
        scheduleSelectedValues = [];
    }

    const _schedule = {
        enabled: schedule?.enabled ?? false,
        hour: hour,
        minute: minute,
        d1: scheduleSelectedValues?.find(x => x.id === 1) ? true : false,
        d2: scheduleSelectedValues?.find(x => x.id === 2) ? true : false,
        d3: scheduleSelectedValues?.find(x => x.id === 3) ? true : false,
        d4: scheduleSelectedValues?.find(x => x.id === 4) ? true : false,
        d5: scheduleSelectedValues?.find(x => x.id === 5) ? true : false,
        d6: scheduleSelectedValues?.find(x => x.id === 6) ? true : false,
        d7: scheduleSelectedValues?.find(x => x.id === 7) ? true : false
    };

    return _schedule;
};

export const getSocketNames = (device) => {
    if(!device) return [];
    
    return [
        device.socket1Name || 'Socket 1',
        device.socket2Name || 'Socket 2',
        device.socket3Name || 'Socket 3',
        device.socket4Name || 'Socket 4'
    ];
}

export const getTimeFromSchedule = (schedule) => {
    if(!schedule || isNaN(Number(schedule.hour)) || isNaN(Number(schedule.minute)) || schedule.hour === -1 || schedule.minute === -1) return '';
    let val = schedule.hour.toFixed() + ":";
    let minute = schedule.minute;
    if (minute < 10) {
        val += "0" + minute.toFixed();
    }
    else {
        val += minute.toFixed();
    }
    return val;
}

export const GetSocketEnableValues = (socketSchedule1On, socketSchedule1Off, socketSchedule2On, socketSchedule2Off, checkInitializedBefore) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [anyScheduleEnabled, setAnyScheduleEnabled] = useState(false);
    const [schedule1Enabled, setSchedule1Enabled] = useState(false);
    const [schedule2Enabled, setSchedule2Enabled] = useState(false);

    useEffect(() => {
        if((checkInitializedBefore && isInitialized) || 
            Object.keys(socketSchedule1On).length < 1 || 
            Object.keys(socketSchedule1Off).length < 1 ||
            Object.keys(socketSchedule2On).length < 1 || 
            Object.keys(socketSchedule2Off).length < 1) return;
        setAnyScheduleEnabled((socketSchedule1On.enabled && socketSchedule1Off.enabled) || (socketSchedule2On.enabled && socketSchedule2Off.enabled));
        setSchedule1Enabled(socketSchedule1On.enabled && socketSchedule1Off.enabled);
        setSchedule2Enabled(socketSchedule2On.enabled && socketSchedule2Off.enabled);
        setIsInitialized(true);
    }, [socketSchedule1On, socketSchedule1Off, socketSchedule2On, socketSchedule2Off, checkInitializedBefore, isInitialized]);

    return {anyScheduleEnabled, schedule1Enabled, schedule2Enabled, setSchedule1Enabled, setSchedule2Enabled};
}

export const GetSocketOnOffValues = (number, deviceValue, checkInitializedBefore) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [socketOn, setSocketOn] = useState(false);
    const [socketInitOn, setSocketInitOn] = useState(false);
    const [socketSchedule1On, setSocketSchedule1On] = useState({});
    const [socketSchedule1Off, setSocketSchedule1Off] = useState({});
    const [socketSchedule2On, setSocketSchedule2On] = useState({});
    const [socketSchedule2Off, setSocketSchedule2Off] = useState({});

    useEffect(() => {
        if((checkInitializedBefore && isInitialized) || !number ||
            !deviceValue || Object.keys(deviceValue.id).length < 1 ) return;

        switch (number) {
            case 1:
                setSocketOn(deviceValue.socket1On ? true : false);
                setSocketSchedule1On(getDataFromCron(deviceValue.schedule_on_1_1));
                setSocketSchedule1Off(getDataFromCron(deviceValue.schedule_off_1_1));
                setSocketSchedule2On(getDataFromCron(deviceValue.schedule_on_1_2));
                setSocketSchedule2Off(getDataFromCron(deviceValue.schedule_off_1_2));
                setSocketInitOn(deviceValue.socket1InitOn ? true : false);
                break;
            case 2:
                setSocketOn(deviceValue.socket2On ? true : false);
                setSocketSchedule1On(getDataFromCron(deviceValue.schedule_on_2_1));
                setSocketSchedule1Off(getDataFromCron(deviceValue.schedule_off_2_1));
                setSocketSchedule2On(getDataFromCron(deviceValue.schedule_on_2_2));
                setSocketSchedule2Off(getDataFromCron(deviceValue.schedule_off_2_2));
                setSocketInitOn(deviceValue.socket2InitOn ? true : false);
                break;
            case 3:
                setSocketOn(deviceValue.socket3On ? true : false);
                setSocketSchedule1On(getDataFromCron(deviceValue.schedule_on_3_1));
                setSocketSchedule1Off(getDataFromCron(deviceValue.schedule_off_3_1));
                setSocketSchedule2On(getDataFromCron(deviceValue.schedule_on_3_2));
                setSocketSchedule2Off(getDataFromCron(deviceValue.schedule_off_3_2));
                setSocketInitOn(deviceValue.socket3InitOn ? true : false);
                break;
            case 4:
                setSocketOn(deviceValue.socket4On ? true : false);
                setSocketSchedule1On(getDataFromCron(deviceValue.schedule_on_4_1));
                setSocketSchedule1Off(getDataFromCron(deviceValue.schedule_off_4_1));
                setSocketSchedule2On(getDataFromCron(deviceValue.schedule_on_4_2));
                setSocketSchedule2Off(getDataFromCron(deviceValue.schedule_off_4_2));
                setSocketInitOn(deviceValue.socket4InitOn ? true : false);
                break;
            default:
                break;
        }

        setIsInitialized(true);
    }, [number, deviceValue, checkInitializedBefore, isInitialized]);

    return {socketOn, socketInitOn, socketSchedule1On, socketSchedule1Off, socketSchedule2On, socketSchedule2Off, setSocketOn, setSocketInitOn};
}