import './ResetPasswordModal.css';
import { useRef } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { useState } from 'react';
import { Box, Fade, FormHelperText, Modal, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { useNotification } from '../../contexts/NotificationContext';
import { keyframes } from '@mui/material';

export const ResetPasswordModal = (props) => {
    const { resetPasswordModalOpen, setResetPasswordModalOpen } = props;
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPasswordValidationMessage, setOldPasswordValidationMessage] = useState('');
    const [newPasswordValidationMessage, setNewPasswordValidationMessage] = useState('');
    const [confirmPasswordValidationMessage, setConfirmPasswordValidationMessage] = useState('');
    const { showNotification } = useNotification();
    const [closeRejected, setCloseRejected] = useState(false);

    const closeButtonRef = useRef();
    const formRef = useRef();

    const onClick = () => {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }

    const validateForm = () => {
        let isValid = true;

        if(!oldPassword || oldPassword.trim() === ''){
            setOldPasswordValidationMessage("Old password is required");
            isValid = false;
        }

        if(!newPassword || newPassword.trim() === ''){
            setNewPasswordValidationMessage("New password is required");
            isValid = false;
        } 

        if(!confirmPassword || confirmPassword.trim() === ''){
            setConfirmPasswordValidationMessage("Confirm password is required");
            isValid = false;
        } else {
            if(newPassword !== confirmPassword){
                setConfirmPasswordValidationMessage("Passwords do not match");
                isValid = false;
            }
        }

        return isValid;
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if(!validateForm()) return;

        // TODO: implement reset password logic here

        showNotification('Password successfully reset.', 'success');
        closeButtonRef.current.click();
    }

    const onOldPaswordChange = (e) => {
        setOldPassword(e.target.value);
        setOldPasswordValidationMessage('');
    }

    const onNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
        setNewPasswordValidationMessage('');
    }

    const onConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordValidationMessage('');
    }

    const growShrink = keyframes`
            0% { transform: scale(1); }
            50% { transform: scale(1.03); }
            100% { transform: scale(1); }
        `;

    const handleClose = (event, reason) => {
        if(reason && reason === 'backdropClick') {
            setCloseRejected(true);

            setTimeout(() => setCloseRejected(false), 300);
            return;
        }

        setResetPasswordModalOpen(false);
    }

    return (
        <Modal 
            open={resetPasswordModalOpen}
            onClose={handleClose} 
            className="reset-password-modal" 
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={resetPasswordModalOpen}>
                <Box className="modal-content"
                    sx={{
                        animation: closeRejected ? `${growShrink} 0.5s ease` : 'none',
                    }}    
                >
                    <Box className="modal-body">
                        <Box className='title-modal mg-b-36'>
                            <button className='btn-gray-close mg-r-16' onClick={() => setResetPasswordModalOpen(false)} ref={closeButtonRef}><CloseIcon /></button>
                            Reset Password
                        </Box>
                        
                        <form onSubmit={onSubmit} ref={formRef}>
                            <TextField
                                id="old-password"
                                name="disable-autofill-old-password"
                                label="Old password"
                                variant="outlined"
                                type="password"
                                fullWidth
                                defaultValue={oldPassword}
                                className='text-input mg-b-16'
                                onChange={onOldPaswordChange}
                                autoComplete='old-password'
                            />
                            <FormHelperText error={true} style={{textAlign: 'right', marginBottom: '12px'}}>
                                <span dangerouslySetInnerHTML={{__html: oldPasswordValidationMessage}}></span>
                            </FormHelperText>
                            <TextField
                                id="new-password"
                                name="disable-autofill-new-password"
                                label="New password"
                                variant="outlined"
                                type="password"
                                fullWidth
                                defaultValue={newPassword}
                                className='text-input mg-b-16'
                                onChange={onNewPasswordChange}
                                autoComplete='new-password'
                            />
                            <FormHelperText error={true} style={{textAlign: 'right', marginBottom: '12px'}}>
                                <span dangerouslySetInnerHTML={{__html: newPasswordValidationMessage}}></span>
                            </FormHelperText>
                            <TextField
                                id="confirm-password"
                                name="disable-autofill-confirm-password"
                                label="Confirm new password"
                                variant="outlined"
                                type="password"
                                fullWidth
                                defaultValue={confirmPassword}
                                className='text-input'
                                onChange={onConfirmPasswordChange}
                                autoComplete='confirm-password'
                            />
                            <FormHelperText error={true} style={{textAlign: 'right', marginBottom: '12px'}}>
                                <span dangerouslySetInnerHTML={{__html: confirmPasswordValidationMessage}}></span>
                            </FormHelperText>
                        </form>
                    </Box>
                    <Box className="modal-footer">
                        <button type="button" className="btn-blue" onClick={onClick}>Save changes</button>
                    </Box>
                    
                </Box>
            </Fade>
        </Modal>
    )
}