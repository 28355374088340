import './RtcModuleSummaryTitleComponent.css';
import { CurrentTimeComponent } from '../Inputs/CurrentTimeComponent';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { atom_timeZonesList, atom_currentLanguage } from '../../infrastructure/jotai/store';
import { getTimeZoneDescriptionTranslated } from '../../infrastructure/helpers/time_helper';

export const RtcModuleSummaryTitleComponent = (props) => {
    const { tank, localIpAddress } = props;

    const [timeZone, setTimeZone] = useState("");
    const [timeZonesList,] = useAtom(atom_timeZonesList);
    const [currentLanguage,] = useAtom(atom_currentLanguage);

    useEffect(() => {
        let tz = getTimeZoneDescriptionTranslated(currentLanguage, tank?.timeZone, timeZonesList);
        
        if (tz && tz.length > 40) {
            tz = tz.substring(0, 35) + "...";
        }
        setTimeZone(tz);
    }, [currentLanguage, tank, timeZonesList]);
    
    return (
        <div className='rtc-summary-title-container'>
            <CurrentTimeComponent tz={tank?.timeZone}/>
            <p className='time-zone'>{timeZone}</p>
            <p className='ip-address'>{localIpAddress}</p>
        </div>
    )
}