const mqttMessageTypes = {
  MQTT_MSG_TYPE_FACTORY_RESET_REQUEST: 1,
  MQTT_MSG_TYPE_FIRMWARE_UPDATE_REQUEST: 2,
  MQTT_MSG_TYPE_MOBILE_OR_WEB_PING_DEVICE: 3,
  MQTT_MSG_TYPE_CONFIG_DATA_REQUEST: 4,
  MQTT_MSG_TYPE_TIMEZONE_CHANGE: 5,
  MQTT_MSG_TYPE_TIME_FROM_SERVER: 6,
  MQTT_MSG_TYPE_MOBILE_OR_WEB_PING_DEVICE_FORCED: 7,
  
  MQTT_MSG_TYPE_TEMPERATURE_PH_READING: 10,
  MQTT_MSG_TYPE_LIGHTS_READING: 11,
  MQTT_MSG_TYPE_FEEDER_READING: 12,
  MQTT_MSG_TYPE_TDS_READING: 13,
  MQTT_MSG_TYPE_RELAY_READING: 14,
  MQTT_MSG_TYPE_DOSER_READING: 15,
  MQTT_MSG_TYPE_RTC_READING: 16,
  MQTT_MSG_TYPE_MAIN_5IN1_READING: 17,
  MQTT_MSG_TYPE_MAIN_8IN1_READING: 18,
  MQTT_MSG_TYPE_COOLER_READING: 19,
  
  MQTT_MSG_TYPE_COOLER_CONFIG_CHANGE: 30,
  MQTT_MSG_TYPE_FEEDER_CONFIG_CHANGE: 31,
  MQTT_MSG_TYPE_FEEDER_MANUAL_FEED: 32,
  MQTT_MSG_TYPE_FEEDER_FEED_EVENT: 33,

  MQTT_MSG_TYPE_RELAY_CONFIG_CHANGE: 34,
  MQTT_MSG_TYPE_RELAY_MANUAL_ACTION: 35,

  MQTT_MSG_TYPE_DOSER_MANUAL_RUN: 36,
  MQTT_MSG_TYPE_DOSER_RUN_EVENT: 37,

  MQTT_MSG_TYPE_LIGHTS_CONFIG_CHANGE: 40,
  MQTT_MSG_TYPE_LIGHTS_MANUAL_VALUE_CHANGE_REQUEST: 41,
  MQTT_MSG_TYPE_LIGHTS_SUNRISE_START: 42,
  MQTT_MSG_TYPE_LIGHTS_SUNRISE_END: 43,
  MQTT_MSG_TYPE_LIGHTS_SUNSET_START: 44,
  MQTT_MSG_TYPE_LIGHTS_SUNSET_END: 45,

  MQTT_MSG_TYPE_CONFIG_DATA_LIGHTS: 51,
  MQTT_MSG_TYPE_CONFIG_DATA_FEEDER: 52,
  MQTT_MSG_TYPE_CONFIG_DATA_RELAY: 53,

  MQTT_MSG_TYPE_DOSER_CONFIG_CHANGE: 60,
  MQTT_MSG_TYPE_DOSER_PUMP_EVENT: 61,
  
  MQTT_MSG_TYPE_WATER_QUALITY_SET_COOLER_TARGET: 70,
  MQTT_MSG_TYPE_WATER_QUALITY_COMMAND_SET_COOLER_ON: 71,
  MQTT_MSG_TYPE_WATER_QUALITY_COMMAND_SET_COOLER_OFF: 72,

};

const lightsOperations = {
  AQUAELF_LIGHTS_OPERATION_NONE: 0,
  AQUAELF_LIGHTS_OPERATION_SUNRISE: 1,
  AQUAELF_LIGHTS_OPERATION_SUNSET: 2
};

export { mqttMessageTypes, lightsOperations };
