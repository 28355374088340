import "./Sidebar.css";

import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";

import { deviceTypeEnum } from "../../infrastructure/helpers/device_types";
import { getDeviceIcon, getDeviceDetails, getIconColor, isActive, getDeviceOnlineStatus } from "../../infrastructure/helpers/sidebar_helper";
import { getOnlineStatusFromLastDataReceived } from "../../infrastructure/helpers/time_helper";
import { Widget } from "./SidebarWidgetDetailComponent";
import { atom_currentLanguage, 
    atom_currentTankId, 
    atom_deviceLastUpdates, 
    atom_devices, 
    atom_deviceValues, 
    atom_feederPumpLastActions, 
    atom_tanks } from "../../infrastructure/jotai/store";

export const Sidebar = (props) => {
    const { currentDeviceType, setCurrentDeviceType, currentDevicePanelScreenType, setCurrentDevicePanelScreenType, currentDeviceId, setCurrentDeviceId } = props;
    const [currentDevices, setCurrentDevices] = useState([]);

    const [devices, ] = useAtom(atom_devices);
    const [feederPumpLastActions,] = useAtom(atom_feederPumpLastActions);
    const [tanks,] = useAtom(atom_tanks);
    const [deviceValues,] = useAtom(atom_deviceValues);
    const [deviceLastUpdates, ] = useAtom(atom_deviceLastUpdates);
    const [currentLanguage,] = useAtom(atom_currentLanguage);
    const [currentTankId,] = useAtom(atom_currentTankId);
    const [isOnline, setIsOnline] = useState(false);

    const [, setCurrentTank] = useState();

    useEffect(() => {
        if (currentTankId) {
            let tank = tanks.find(x => x.id === currentTankId);
            setCurrentTank(tank);
            setCurrentDevices(devices.filter(x => x.tankId === currentTankId && x.screenVisible)
                                .sort((a, b) => a.orderIndex - b.orderIndex));
        }
    }, [tanks, currentTankId, devices]);

    useEffect(() => {
        setIsOnline(getOnlineStatusFromLastDataReceived(deviceLastUpdates.reduce((a, b) => new Date(a.lastDataReceived) > new Date(b.lastDataReceived) ? a : b, {lastDataReceived: null})));
    }, [deviceLastUpdates]);

    const navigateModule = (device) => {
        setCurrentDeviceId(device.deviceId);
        setCurrentDeviceType(device.type);
        setCurrentDevicePanelScreenType(device.panelScreenType);
    }

    return (
        <div className="card card-container sidebar">
            <div className="card-body">                
                <div className="row gutter-x-8">
                    {currentDevices.map((device) => (
                        <div className={device.type === deviceTypeEnum.RTC ? "col-12" : "col-6"} key={device.id}>
                            <div onClick={() => navigateModule(device)}>
                                <Widget                                
                                    icon={getDeviceIcon(device)}
                                    title={device.name} 
                                    details={getDeviceDetails(device, deviceValues, feederPumpLastActions, tanks, currentTankId, isOnline, currentLanguage)} 
                                    iconContainerColor={getIconColor(device.type, device.panelScreenType)}
                                    isActive={isActive(device, currentDeviceId, currentDeviceType, currentDevicePanelScreenType)}
                                    isOnline={getDeviceOnlineStatus(device, deviceLastUpdates)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="card-footer-muted mg-t-10">
                <p className="text-muted-l">New modules can be added via mobile</p>
            </div>
        </div>
    )
}